import React, { useEffect } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { useAuth0 } from 'react-auth0-spa'
import Unauthorized from 'pages/Unauthorized'

const PrivateRoute: React.FC<RouteProps> = (props) => {
  const { component, path, ...rest } = props
  const [insufficientPermission, setInsufficientPermission] =
    React.useState(false)
  const { getTokenSilently, loading, isAuthenticated, loginWithRedirect } =
    useAuth0()

  useEffect(() => {
    if (loading || isAuthenticated) {
      return
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
      })
    }
    fn()
  }, [loading, isAuthenticated, loginWithRedirect, path])

  useEffect(() => {
    const fn = async () => {
      const token = await getTokenSilently()
      if (token) {
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        const claims = JSON.parse(
          decodeURIComponent(escape(window.atob(base64))),
        )
        if (
          !claims.permissions.includes('write') ||
          !claims.permissions.includes('read')
        ) {
          setInsufficientPermission(true)
        }
      }
    }
    fn()
  }, [isAuthenticated, getTokenSilently])

  if (!isAuthenticated) {
    return null
  }

  if (insufficientPermission) {
    return <Unauthorized />
  }

  return (
    <Route path={path} component={component} render={undefined} {...rest} />
  )
}

export default PrivateRoute
