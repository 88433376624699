import React from 'react'
import { hot } from 'react-hot-loader/root'
import Loading from 'components/Loading'
import { useAuth0 } from 'react-auth0-spa'
import { Route, Router, Switch, Redirect } from 'react-router-dom'
import history from 'utils/history'
import OrdersPage from 'pages/Orders'
import EstimatesPage from 'pages/Estimates'
import AddressesPage from 'pages/Addresses'
import ProductsPage from 'pages/Products'
import ProductPage from 'pages/Product'
import OrderPage from 'pages/Order'
import ViewOrderPage from 'pages/ViewOrder'
import EstimatePage from 'pages/Estimate'
import UsersPage from 'pages/Users'
import NotFoundPage from 'pages/NotFound'
import CouponsPage from 'pages/Coupons'
import ShipmentListPage from 'pages/ShipmentList'
import PrivateRoute from 'components/PrivateRoute'

const App: React.FC = () => {
  const { loading } = useAuth0()

  if (loading) {
    return <Loading text="認証中..." />
  }
  return (
    <div className="font-sans">
      <Router history={history}>
        <Switch>
          <Redirect from="/" to="/orders" exact={true} />
          <PrivateRoute exact={true} path="/orders" component={OrdersPage} />
          <PrivateRoute exact={true} path="/orders/:id" component={OrderPage} />
          <PrivateRoute
            exact={true}
            path="/orders/:id/view"
            component={ViewOrderPage}
          />
          <PrivateRoute exact={true} path="/users" component={UsersPage} />
          <PrivateRoute
            exact={true}
            path="/estimates"
            component={EstimatesPage}
          />
          <PrivateRoute
            exact={true}
            path="/estimates/:estimateNumber"
            component={EstimatePage}
          />
          <PrivateRoute
            exact={true}
            path="/products"
            component={ProductsPage}
          />
          <PrivateRoute
            exact={true}
            path="/products/:productId"
            component={ProductPage}
          />
          <PrivateRoute
            exact={true}
            path="/shipments/:date"
            component={ShipmentListPage}
          />
          <PrivateRoute
            exact={true}
            path="/addresses"
            component={AddressesPage}
          />
          <PrivateRoute exact={true} path="/coupons" component={CouponsPage} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Router>
    </div>
  )
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App
