import * as React from 'react'
import NavBar from 'components/NavBar'
import { ReactTabulator } from 'react-tabulator'
import { useAuth0 } from 'react-auth0-spa'
import axios from 'axios'
import Loading from 'components/Loading'
import dayjs from 'dayjs'
import { ButtonAdd } from 'components/Button'

const queryGen = (keyword: string, after?: string, before?: string) => {
  let url = `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/estimates?keyword=${keyword}`
  if (after) {
    url = `${url}&after=${after}`
  }
  if (before) {
    url = `${url}&before=${before}`
  }
  return url
}

const EstimatesPage: React.FC = () => {
  const { getTokenSilently } = useAuth0()
  const [token, setToken] = React.useState<string | undefined>('')
  const [estimates, setEstimates] = React.useState<any[] | null>(null)
  const [filterKeyword, setFilterKeyword] = React.useState<string>('')
  const [after, setAfter] = React.useState<string>(
    dayjs().add(-1, 'month').format('YYYY-MM-DD'),
  )
  const [before, setBefore] = React.useState<string>(
    dayjs().add(1, 'day').format('YYYY-MM-DD'),
  )

  // トークンセット
  React.useEffect(() => {
    getTokenSilently().then((t: string | undefined) => {
      setToken(t)
    })
  }, [getTokenSilently])

  const fetchEstimates = React.useCallback(() => {
    setEstimates(null)
    axios.defaults.headers.common['Authorization'] = token
    const u = queryGen(filterKeyword, after, before)
    axios
      .get(u)
      .then((res) => {
        if (res.data.data) {
          const data = res.data.data.filter((d: any) => {
            return d.estimateFrom !== 'cart'
          })
          setEstimates(data)
        } else if (res.data.data === null) {
          setEstimates([])
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [token, filterKeyword, after, before])

  const addEstimate = React.useCallback(() => {
    axios.defaults.headers.common['Authorization'] = token
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/estimates`)
      .then((res) => {
        fetchEstimates()
      })
      .catch((err) => {
        window.alert(err)
      })
  }, [token, fetchEstimates])

  // データ取得
  React.useEffect(() => {
    if (token === '') {
      return
    }
    fetchEstimates()
  }, [token, fetchEstimates])

  if (!estimates) {
    return <Loading text="見積情報読み込み中..." />
  }

  const columns = [
    {
      title: '見積番号',
      field: 'estimateNumber',
      hozAlign: 'center',
      width: 130,
    },
    {
      title: '依頼日時',
      field: 'createdAt',
      hozAlign: 'center',
      width: 150,
      formatter: (cell: any) => {
        const unixtime = cell.getValue() * 1000
        if (unixtime === 0) {
          return '--'
        }
        return dayjs(unixtime).format('YYYY/MM/DD HH:mm:ss')
      },
    },
    {
      title: '見積元',
      field: 'estimateFrom',
      hozAlign: 'center',
      width: 100,
    },
    {
      title: 'ステータス',
      field: 'status',
      hozAlign: 'center',
      width: 110,
    },
    {
      title: 'お客様メールアドレス',
      field: 'email',
      hozAlign: 'left',
      width: 250,
    },
    { title: 'お客様名', field: 'userName', hozAlign: 'left', width: 250 },
    { title: '宛名', field: 'to', hozAlign: 'left', width: 250 },
    { title: '件名', field: 'title', width: 600 },
  ]

  const navigateToOrder = (e: React.SyntheticEvent, row: any) => {
    window.open(`/estimates/${row.getData().estimateNumber}`)
  }

  const duplicateEstimate = (e: React.SyntheticEvent, row: any) => {
    e.preventDefault()
    const yes = window.confirm(
      '右クリックした見積を複製しますがよろしいですか？',
    )
    if (yes) {
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/estimates/${
            row.getData().estimateNumber
          }/duplicate`,
        )
        .then((res) => {
          fetchEstimates()
        })
        .catch((err: Error) => {
          alert(err)
          fetchEstimates()
        })
    }
  }

  const handleInputKeyword = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    setFilterKeyword(ev.currentTarget['filter-keyword'].value)
  }

  const handleInputAfter = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    setAfter(ev.currentTarget['filter-after'].value)
  }

  const handleInputBefore = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    setBefore(ev.currentTarget['filter-before'].value)
  }

  const clearTabulatorPersistence = () => {
    localStorage.setItem('tabulator-backofficeEstimates-filter', '')
    localStorage.setItem('tabulator-backofficeEstimates-sort', '')
    localStorage.setItem('tabulator-backofficeEstimates-columns', '')
    window.location.reload()
  }
  return (
    <>
      <header>
        <NavBar />
      </header>
      <div className="flex flex-col">
        <div className="h-12 p-2 flex justify-between">
          <ButtonAdd
            type="button"
            onClick={() => {
              if (window.confirm('よろしいですか？')) {
                addEstimate()
              }
            }}
          />
          <div className="flex items-center justify-start">
            <form onSubmit={handleInputKeyword}>
              <span className="text-xs px-2">フィルタ:</span>{' '}
              <input
                className="border border-solid border-gray-300 rounded px-2"
                name="filter-keyword"
                type="text"
                defaultValue={filterKeyword}
              />
              <button type="submit" className="hidden" />
            </form>
            <span className="text-xs px-2 ml-2">期間(yyyy-mm-dd):</span>{' '}
            <form onSubmit={handleInputAfter}>
              <input
                className="border border-solid border-gray-300 rounded px-2 w-32"
                name="filter-after"
                type="text"
                defaultValue={after}
              />
              <button type="submit" className="hidden" />
            </form>
            ～
            <form onSubmit={handleInputBefore}>
              <input
                className="border border-solid border-gray-300 rounded px-2 w-32"
                name="filter-before"
                type="text"
                defaultValue={before}
              />
              <button type="submit" className="hidden" />
            </form>
            <button
              className="border px-4 rounded hover:bg-gray-200 mx-2"
              onClick={fetchEstimates}
            >
              データ再取得
            </button>
          </div>
          <div className="flex items-center justify-end">
            <button
              className="border px-4 rounded hover:bg-gray-200 mx-2"
              onClick={clearTabulatorPersistence}
            >
              テーブル設定初期化
            </button>
          </div>
        </div>
        <ReactTabulator
          height={window.innerHeight - 80}
          data={estimates}
          columns={columns}
          tooltips={true}
          layout="fitData"
          rowClick={navigateToOrder}
          rowContext={duplicateEstimate}
          options={{
            persistence: true,
            persistenceMode: 'local',
            persistenceID: 'backofficeEstimates',
            persistenceLayout: true,
            movableColumns: true,
          }}
        />
      </div>
    </>
  )
}

export default EstimatesPage
