import React from 'react'
import Input from 'components/Form/Input'
import { Field, FieldProps, Form, Formik } from 'formik'

interface Props {
  onClose: () => void
  onSubmit: (values: any) => void
  purchase: any
}

export const DeliveryInfo = (props: Props) => {
  const d = props.purchase
  const handleClose = () => {
    props.onClose()
  }
  const handleSubmit = (values: any) => {
    props.onSubmit(values)
  }
  return (
    <div className="fixed h-full w-full top-0 left-0 flex items-center justify-center z-30">
      <div
        className="absolute h-full w-full top-0 left-0 bg-black opacity-50 z-40"
        onClick={props.onClose}
      />
      <div className="absolute bg-white z-50 w-1/2 min-h-64 p-4 rounded shadow overflow-scroll max-h-screen">
        <h1 className="text-2xl p-2 font-bold">配送情報の変更</h1>
        <div className="p-2">
          <p>配送情報を変更します</p>
          <ul className="p-2 list-disc ml-4">
            <li>
              お客様に配送情報を変更した旨は
              <strong className="text-red-700">通知されません</strong>
            </li>
            <li>
              この支払いに紐づく注文のみに影響します
              <br />
              ※お客様が事前に登録しているアカウント情報に紐づく、出荷人・配送先情報には影響しません
            </li>
            <li className="text-red-500">
              ※GMO後払いの場合は
              <a
                href="https://shop2.gmo-ab.com/gmo/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700 hover:underline hover:text-blue-800"
              >
                GMO後払いの管理画面
              </a>
              と情報を合わせてください
            </li>
          </ul>
        </div>
        <Formik
          initialValues={{ ...d }}
          onSubmit={(values, actions) => {
            handleSubmit(values)
          }}
        >
          {(props) => (
            <>
              <Form className="flex-1">
                <div className="w-3/4 mx-auto">
                  <Field name="dest_organization">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return (
                        <Input type="text" label="配送先 : 組織名" {...field} />
                      )
                    }}
                  </Field>
                  <div className="flex">
                    <Field name="dest_family_name">
                      {(fieldProps: FieldProps) => {
                        const { field } = fieldProps
                        return (
                          <Input type="text" label="配送先 : 姓" {...field} />
                        )
                      }}
                    </Field>
                    <Field name="dest_given_name">
                      {(fieldProps: FieldProps) => {
                        const { field } = fieldProps
                        return (
                          <Input type="text" label="配送先 : 名" {...field} />
                        )
                      }}
                    </Field>
                  </div>
                  <div className="flex">
                    <Field name="dest_family_kana">
                      {(fieldProps: FieldProps) => {
                        const { field } = fieldProps
                        return (
                          <Input type="text" label="配送先 : セイ" {...field} />
                        )
                      }}
                    </Field>
                    <Field name="dest_given_kana">
                      {(fieldProps: FieldProps) => {
                        const { field } = fieldProps
                        return (
                          <Input type="text" label="配送先 : メイ" {...field} />
                        )
                      }}
                    </Field>
                  </div>
                  <div className="flex">
                    <Field name="dest_postal_code">
                      {(fieldProps: FieldProps) => {
                        const { field } = fieldProps
                        return (
                          <Input
                            type="postal"
                            label="配送先 : 郵便番号"
                            {...field}
                          />
                        )
                      }}
                    </Field>
                    <Field name="dest_address_level1">
                      {(fieldProps: FieldProps) => {
                        const { field } = fieldProps
                        return (
                          <Input
                            type="text"
                            label="配送先 : 都道府県"
                            {...field}
                          />
                        )
                      }}
                    </Field>
                  </div>
                  <Field name="dest_address_level2">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return (
                        <Input
                          type="text"
                          label="配送先 : 市区町村"
                          {...field}
                        />
                      )
                    }}
                  </Field>
                  <Field name="dest_address_line1">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return (
                        <Input
                          type="text"
                          label="配送先 : 以降の住所"
                          {...field}
                        />
                      )
                    }}
                  </Field>
                  <Field name="dest_address_line2">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return (
                        <Input
                          type="text"
                          label="配送先 : ビル名、部屋番号等"
                          {...field}
                        />
                      )
                    }}
                  </Field>
                  <Field name="dest_tel">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return (
                        <Input
                          type="text"
                          label="配送先 : 電話番号"
                          {...field}
                        />
                      )
                    }}
                  </Field>
                  <hr className="my-2" />
                  <Field name="src_organization">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return (
                        <Input type="text" label="配送元 : 組織名" {...field} />
                      )
                    }}
                  </Field>
                  <div className="flex">
                    <Field name="src_family_name">
                      {(fieldProps: FieldProps) => {
                        const { field } = fieldProps
                        return (
                          <Input type="text" label="配送元 : 姓" {...field} />
                        )
                      }}
                    </Field>
                    <Field name="src_given_name">
                      {(fieldProps: FieldProps) => {
                        const { field } = fieldProps
                        return (
                          <Input type="text" label="配送元 : 名" {...field} />
                        )
                      }}
                    </Field>
                  </div>
                  <div className="flex">
                    <Field name="src_family_kana">
                      {(fieldProps: FieldProps) => {
                        const { field } = fieldProps
                        return (
                          <Input type="text" label="配送元 : セイ" {...field} />
                        )
                      }}
                    </Field>
                    <Field name="src_given_kana">
                      {(fieldProps: FieldProps) => {
                        const { field } = fieldProps
                        return (
                          <Input type="text" label="配送元 : メイ" {...field} />
                        )
                      }}
                    </Field>
                  </div>
                  <div className="flex">
                    <Field name="src_postal_code">
                      {(fieldProps: FieldProps) => {
                        const { field } = fieldProps
                        return (
                          <Input
                            type="postal"
                            label="配送元 : 郵便番号"
                            {...field}
                          />
                        )
                      }}
                    </Field>
                    <Field name="src_address_level1">
                      {(fieldProps: FieldProps) => {
                        const { field } = fieldProps
                        return (
                          <Input
                            type="text"
                            label="配送元 : 都道府県"
                            {...field}
                          />
                        )
                      }}
                    </Field>
                  </div>
                  <Field name="src_address_level2">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return (
                        <Input
                          type="text"
                          label="配送元 : 市区町村"
                          {...field}
                        />
                      )
                    }}
                  </Field>
                  <Field name="src_address_line1">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return (
                        <Input
                          type="text"
                          label="配送元 : 以降の住所"
                          {...field}
                        />
                      )
                    }}
                  </Field>
                  <Field name="src_address_line2">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return (
                        <Input
                          type="text"
                          label="配送元 : ビル名、部屋番号等"
                          {...field}
                        />
                      )
                    }}
                  </Field>
                  <Field name="src_tel">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return (
                        <Input
                          type="text"
                          label="配送元 : 電話番号"
                          {...field}
                        />
                      )
                    }}
                  </Field>
                  <hr />
                  <Field name="delivery_time">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return (
                        <Input
                          type="text"
                          label="時間指定(none/am/pm)"
                          {...field}
                        />
                      )
                    }}
                  </Field>
                </div>
                <div className="flex justify-end">
                  <div className="p-1">
                    <button
                      className="button p-2 bg-green-500 rounded text-white hover:bg-green-600"
                      type="submit"
                    >
                      変更する
                    </button>
                  </div>
                  <div className="p-1">
                    <button
                      className="button p-2 bg-red-500 rounded text-white hover:bg-red-600"
                      type="button"
                      onClick={handleClose}
                    >
                      キャンセル
                    </button>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  )
}
