import * as React from 'react'
import { useFormikContext } from 'formik'
import { Estimate, User } from 'types'

type Props = {
  index: number
}

type Value = {
  estimate: Estimate
  user: User
}

export default (props: Props) => {
  const { index } = props
  const { setFieldValue, values } = useFormikContext<Value>()
  let amount: number = Number(
    values.estimate.response.children[index].main.price,
  )
  for (const o of values.estimate.response.children[index].options) {
    amount = Number(amount) + Number(o.price)
  }
  let taxRate: number = 10
  let tax: number = Math.ceil(amount * (taxRate * 0.01))
  let amountWithTax: number = amount + tax

  React.useEffect(() => {
    setFieldValue(`estimate.response.children[${index}].amount`, amount)
    setFieldValue(`estimate.response.children[${index}].tax_rate`, 10)
    setFieldValue(`estimate.response.children[${index}].tax`, tax)
    setFieldValue(
      `estimate.response.children[${index}].amount_with_tax`,
      amountWithTax,
    )
  }, [amount, amountWithTax, tax, index, setFieldValue])

  return (
    <div className="flex w-full items-center justify-around py-4">
      <div className="text-lg">小計: {amount}円</div>
      <div className="text-lg">消費税率: {taxRate}%</div>
      <div className="text-lg">消費税: {tax}円</div>
      <div className="text-xl font-bold">合計: {amountWithTax}円</div>
    </div>
  )
}
