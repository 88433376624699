import React from 'react'
import { IconLoaderDots } from 'components/Icons'

type Props = JSX.IntrinsicElements['button'] & {
  color?: 'primary' | 'danger' | 'info' | 'white' | 'disabled'
  size?: 'small' | 'medium' | 'large'
  requesting?: boolean
}

export const Button = (props: React.PropsWithChildren<Props>) => {
  let { children, color, size, requesting, ...rest } = props
  let className =
    'rounded select-none font-bold outline-none relative border whitespace-nowrap'
  if (requesting) {
    color = 'disabled'
  }
  switch (color) {
    case 'primary':
      className = `${className} border-transparent bg-primary-300 hover:bg-primary-400 active:bg-primary-500 text-white`
      break
    case 'danger':
      className = `${className} border-transparent bg-red-500 hover:bg-red-600 active:bg-red-700 text-white`
      break
    case 'info':
      className = `${className} border-transparent bg-blue-500 hover:bg-blue-600 active:bg-blue-700 text-white`
      break
    case 'white':
      className = `${className} border-zinc-500 bg-white hover:bg-zinc-100 active:bg-zinc-200 text-black`
      break
    case 'disabled':
      className = `${className} border-transparent bg-zinc-400 text-white pointer-events-none`
      break
  }
  switch (size) {
    case 'small':
      className = `${className} text-xs py-1 px-4`
      break
    case 'medium':
      className = `${className} text-base py-1 px-4`
      break
    case 'large':
      className = `${className} text-3xl py-2 px-8`
      break
    default:
      className = `${className} text-base py-1 px-4`
  }
  return (
    <button type="button" className={className} {...rest}>
      {requesting ? (
        <>
          <div className="absolute inset-0 flex justify-center items-center">
            <IconLoaderDots size={size} />
          </div>
          <div className="opacity-0">{children}</div>
        </>
      ) : (
        <div className="opacity-100">{children}</div>
      )}
    </button>
  )
}
