import * as React from 'react'
import Input from 'components/Form/Input'
import Child from './response-child'
import { Field, FieldProps, FieldArray } from 'formik'
import { Estimate } from 'types'

type Props = {
  setFieldValue: any
  estimate: Estimate
}

const initialChild = {
  no: 0,
  name: 'パターンA',
  main: {
    name: 'シール印刷・加工',
    num: 0,
    price: 0,
    unit_price: 0,
    specification: `材質:
ラミ:
サイズ: mm x mm
形状:
刷色:
加工:
仕上げ:
納期: データチェック完了後、約7-10営業日後発送
（納期は目安となります。発注のタイミング・複数のご注文などで変わる場合があります。）
納品先: 貴社指定場所（海外発送は対応しておりません）
※印刷用完全データ支給（別レイヤーでのカットラインデータ必須）`,
  },
  options: [],
}

export default (props: Props) => {
  const { estimate, setFieldValue } = props
  return (
    <div>
      <Field name="estimate.response.to">
        {(fieldProps: FieldProps) => {
          const { field, meta } = fieldProps
          return (
            <div className="w-full px-1">
              <Input
                type="text"
                label="宛名"
                setFieldValue={setFieldValue}
                {...field}
              />
              {meta.touched && meta.error && meta.error}
            </div>
          )
        }}
      </Field>
      <div className="flex">
        <Field name="estimate.response.issued_at">
          {(fieldProps: FieldProps) => {
            const { field, meta } = fieldProps
            return (
              <div className="w-full px-1">
                <Input
                  type="date"
                  label="発行日"
                  setFieldValue={setFieldValue}
                  {...field}
                />
                {meta.touched && meta.error && meta.error}
              </div>
            )
          }}
        </Field>
        <Field name="estimate.response.expiration_at">
          {(fieldProps: FieldProps) => {
            const { field, meta } = fieldProps
            return (
              <div className="w-full px-1">
                <Input
                  type="date"
                  label="有効期限"
                  setFieldValue={setFieldValue}
                  {...field}
                />
                {meta.touched && meta.error && meta.error}
              </div>
            )
          }}
        </Field>
      </div>
      <FieldArray
        name="estimate.response.children"
        render={(arrayHelpers) => {
          if (!estimate.response.children) {
            return (
              <div className="flex justify-center children center w-full p-4">
                <button
                  type="button"
                  className="border py-1 px-4"
                  onClick={() => {
                    arrayHelpers.push({ ...initialChild, ...{ no: 1 } })
                  }}
                >
                  小見積もり追加
                </button>
              </div>
            )
          }
          const itemNum: number = estimate.response.children.length
          if (itemNum === 0) {
            return (
              <div className="flex justify-center children center w-full p-4">
                <button
                  type="button"
                  className="border py-1 px-4"
                  onClick={() => {
                    arrayHelpers.push({ ...initialChild, ...{ no: 1 } })
                  }}
                >
                  小見積もり追加
                </button>
              </div>
            )
          }
          const children = estimate.response.children.map((_, index) => {
            return (
              <div key={index}>
                <hr className="dashed border border-gray-500" />
                <Child
                  index={index}
                  setFieldValue={setFieldValue}
                  arrayHelpers={arrayHelpers}
                  estimate={estimate}
                />
              </div>
            )
          })
          const lastChild = estimate.response.children[itemNum - 1]
          return (
            <div>
              {children}
              <div className="flex justify-center children center w-full p-4">
                <button
                  type="button"
                  className="border py-1 px-4"
                  onClick={() => {
                    const newChild = {
                      ...lastChild,
                      ...{ no: itemNum + 1 },
                    }
                    arrayHelpers.push(newChild)
                  }}
                >
                  小見積もり追加
                </button>
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}
