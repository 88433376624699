export * from './history'
export * from './order'
export * from './orderstatuses'
export * from './product'

export const paymentName = (name: string): string => {
  switch (name) {
    case 'unknown':
      return '不明'
    case 'epsilon':
      return 'クレジットカード'
    case 'bank':
      return '銀行振込(UFJ)'
    case 'onDelivery':
      return '代金引換'
    case 'gmoab':
      return 'GMO後払い'
    case 'gmokb':
      return 'GMO掛け払い'
    case 'vbank':
      return '銀行振込(バーチャル口座)'
    case 'paypay':
      return 'PayPay'
    case 'point':
      return 'ポイント全額払い'
    default:
      return '不明'
  }
}
