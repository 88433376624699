import React from 'react'
import dayjs from 'dayjs'

type Props = { data: any }

const productName = (productType: string, id: number, products: any) => {
  for (const p of products[productType]) {
    if (id === p.value) {
      return p.name
    }
  }
}

function OutsourceMail(props: Props) {
  const { data } = props
  const { order, purchase, user, products, estimateData } = data
  const [copied, setCopied] = React.useState(false)
  const userName =
    `${user.organization} ${user.family_name} ${user.given_name}`.trim()
  const subject = `${userName}様分 印刷手配をお願いします。`
  console.log(data)
  let deliveryTime = 'なし'
  switch (purchase.delivery_time) {
    case 'none':
      deliveryTime = 'なし'
      break
    case 'am':
      deliveryTime = '午前中'
      break
    case 'pm':
      deliveryTime = '午後'
      break
  }
  const header = `澤木部長

お疲れ様です。アドバルです。
Hitlabelの受注が確定しましたので、印刷手配をお願いします。
入稿データは "share6/●07＞HITLABEL＞外注案件データ" に入れております。
仕様は下記の通りです。

注文No. ${order.order_id}
クライアント: ${userName}
商品名: ${order.name}`

  const shipment = `■お届け先
法人名: ${purchase.dest_organization}
担当者名: ${purchase.dest_family_name} ${purchase.dest_given_name}
ふりがな: ${purchase.dest_family_kana} ${purchase.dest_given_kana}
郵便番号: ${purchase.dest_postal_code}
都道府県: ${purchase.dest_address_level1}
市区町村: ${purchase.dest_address_level2}
以降の住所: ${purchase.dest_address_line1}
ビル名、部屋番号等: ${purchase.dest_address_line2}
電話番号: ${purchase.dest_tel}
配送希望時間: ${deliveryTime}

■出荷人（荷主）
法人名: ${purchase.src_organization}
担当者名: ${purchase.src_family_name} ${purchase.src_given_name}
ふりがな: ${purchase.src_family_kana} ${purchase.src_given_kana}
郵便番号: ${purchase.src_postal_code}
都道府県: ${purchase.src_address_level1}
市区町村: ${purchase.src_address_level2}
以降の住所: ${purchase.src_address_line1}
ビル名、部屋番号等: ${purchase.src_address_line2}
電話番号: ${purchase.src_tel}`
  let body = `${header}

サイズ: ${order.size_x}㎜ × ${order.size_y}㎜
材質: ${productName('material', order.product_id, products)}
ラミ: ${productName('lamination', order.lamination, products)}
接着糊: ${productName('adhesive', order.adhesive, products)}
裏スリット: ${productName('slit', order.slit, products)}
控え発送: ${productName('sendCopy', order.send_copy, products)}
数量: ${order.num}
納品形態: ${productName('deliveryForm', order.delivery_form, products)}

発送予定日: ${dayjs(order.shipping_date * 1000).format('YYYY年MM月DD日')}

${shipment}

以上、よろしくお願いいたします。
`
  if (estimateData && estimateData.child) {
    body = `${header}
見積番号: ${estimateData.estimate.estimateNumber}
見積小番号: ${estimateData.child.no}
パターン名: ${estimateData.child.name}

${estimateData.child.main.specification}

発送予定日: ${dayjs(order.shipping_date * 1000).format('YYYY年MM月DD日')}

${shipment}

以上、よろしくお願いいたします。
  `
  }
  const handleClick = () => {
    window.location.href = `mailto:hitlabel@fujiwara-p.co.jp?subject=${subject}`
    navigator.clipboard.writeText(body).then(
      () => {
        setCopied(true)
        window.setTimeout(() => {
          setCopied(false)
        }, 5000)
      },
      (err) => {
        window.alert(err)
      },
    )
  }

  if (copied) {
    return (
      <button className="px-4 text-black cursor-default" type="button">
        本文をクリップボードにコピーしました。メール本文に貼り付けてください。
      </button>
    )
  }

  return (
    <button
      className="px-4 hover:underline text-blue-500 hover:text-blue-600 active:text-blue-700"
      onClick={handleClick}
    >
      外注メール作成
    </button>
  )
}

export { OutsourceMail }
