import React, { useState, useEffect } from 'react'
import axios from 'axios'

type Props = {
  order_id: string
}

function QR(props: Props) {
  const [img, setImg] = useState('')
  const { order_id } = props

  useEffect(() => {
    if (!order_id) return
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/orders/${order_id}/qr`,
      responseType: 'blob',
    })
      .then((res) => {
        const imageURL = URL.createObjectURL(res.data)
        setImg(imageURL)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [order_id])

  console.log(img)

  return (
    <div>
      <img src={img} alt="qr" />
    </div>
  )
}

export { QR }
