export const estimateDoneTemplate = (
  userName: string,
  estimateNumber: number,
) => {
  return `${userName} 様

いつもご利用いただき誠にありがとうございます。
シール・ステッカー印刷のヒット・ラベルです。

ご依頼頂きましたお見積りが完成いたしましたので、送付いたします。

お見積り番号: ${estimateNumber}

※また、お見積書は有効期限内であればマイページよりダウンロードが出来ます。
（マイページ: https://hitlabel.jp/mypage ）

その後のご注文も、Web上にて決済等ができますのでぜひご利用ください。

何かご不明な点などございましたら、お気軽にお問合わせ下さい。

◆ オリジナルで作れるシール・ステッカー印刷の「ヒット・ラベル」◆━━━━━━━━━━━━━━━━━━

株式会社ヒット・ラベル
〒532-0011　大阪府大阪市淀川区西中島4-13-5
TEL:06-4862-5634　　FAX:06-4862-5621
https://hitlabel.jp

_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/
  `
}
