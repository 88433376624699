import * as React from 'react'
import { ReactTabulator } from 'react-tabulator'
import { useAuth0 } from 'react-auth0-spa'
import axios from 'axios'
import Loading from 'components/Loading'
import dayjs from 'dayjs'
import { ButtonAdd } from 'components/Button'

const ProductsPage: React.FC = () => {
  const { getTokenSilently } = useAuth0()
  const [token, setToken] = React.useState<string | undefined>('')
  const [products, setProducts] = React.useState<any[] | null>(null)

  // トークンセット
  React.useEffect(() => {
    getTokenSilently().then((t: string | undefined) => {
      setToken(t)
    })
  }, [getTokenSilently])

  const fetchProducts = React.useCallback(() => {
    setProducts(null)
    axios.defaults.headers.common['Authorization'] = token
    const u = `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/products`
    axios
      .get(u)
      .then((res) => {
        if (res.data.data) {
          const data = res.data.data.filter((d: any) => {
            return d.estimateFrom !== 'cart'
          })
          setProducts(data)
        } else if (res.data.data === null) {
          setProducts([])
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [token])

  const addEstimate = React.useCallback(() => {
    axios.defaults.headers.common['Authorization'] = token
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/estimates`)
      .then((res) => {
        fetchProducts()
      })
      .catch((err) => {
        window.alert(err)
      })
  }, [token, fetchProducts])

  // データ取得
  React.useEffect(() => {
    if (token === '') {
      return
    }
    fetchProducts()
  }, [token, fetchProducts])

  if (!products) {
    return <Loading text="商品情報読み込み中..." />
  }

  const columns = [
    {
      title: 'ID',
      field: 'id',
      hozAlign: 'right',
      width: 40,
    },
    {
      title: '作成日',
      field: 'created_at',
      hozAlign: 'center',
      width: 120,
      formatter: (cell: any) => {
        const unixtime = cell.getValue() * 1000
        if (unixtime === 0) {
          return '--'
        }
        return dayjs(unixtime).format('YYYY/MM/DD')
      },
    },
    {
      title: '更新日',
      field: 'updated_at',
      hozAlign: 'center',
      width: 120,
      formatter: (cell: any) => {
        const unixtime = cell.getValue() * 1000
        if (unixtime === 0) {
          return '--'
        }
        return dayjs(unixtime).format('YYYY/MM/DD')
      },
    },
    {
      title: '削除日',
      field: 'deleted_at',
      hozAlign: 'center',
      width: 120,
      formatter: (cell: any) => {
        const unixtime = cell.getValue() * 1000
        if (unixtime === 0) {
          return '--'
        }
        return dayjs(unixtime).format('YYYY/MM/DD')
      },
    },
    {
      title: '名前',
      field: 'name',
      width: 240,
    },
    {
      title: '名前(詳細)',
      field: 'name_detail',
      width: 240,
    },
    {
      title: '商品種別',
      field: 'product_type',
      width: 110,
    },
    {
      title: 'カテゴリ',
      field: 'category',
      width: 80,
    },
    {
      title: '価格',
      field: 'price',
      width: 80,
    },
    {
      title: '説明',
      field: 'description',
      width: 80,
    },
    {
      title: '表示',
      field: 'visible',
      width: 80,
    },
    {
      title: 'カット割合',
      field: 'cut_rate',
      width: 80,
    },
    {
      title: '紙代',
      field: 'paper_price',
      width: 80,
    },
    {
      title: '印刷代',
      field: 'print_price',
      width: 80,
    },
    {
      title: 'カット代',
      field: 'cut_price',
      width: 80,
    },
    {
      title: '送料',
      field: 'delivery_price',
      width: 80,
    },
    {
      title: '割引タイプ',
      field: 'discount_type',
      width: 80,
    },
    {
      title: 'カットタイプ',
      field: 'cut_type',
      width: 80,
    },
  ]

  const navigateToProduct = (e: React.SyntheticEvent, row: any) => {
    window.open(`/products/${row.getData().id}`)
  }
  const clearTabulatorPersistence = () => {
    localStorage.setItem('tabulator-backofficeProducts-filter', '')
    localStorage.setItem('tabulator-backofficeProducts-sort', '')
    localStorage.setItem('tabulator-backofficeProducts-columns', '')
    window.location.reload()
  }
  return (
    <div className="flex flex-col">
      <div className="h-12 p-2 flex justify-between">
        <ButtonAdd
          type="button"
          onClick={() => {
            if (window.confirm('よろしいですか？')) {
              addEstimate()
            }
          }}
        />
        <div className="flex items-center justify-start">
          <button
            className="border px-4 rounded hover:bg-gray-200 mx-2"
            onClick={fetchProducts}
          >
            データ再取得
          </button>
        </div>
        <div className="flex items-center justify-end">
          <button
            className="border px-4 rounded hover:bg-gray-200 mx-2"
            onClick={clearTabulatorPersistence}
          >
            テーブル設定初期化
          </button>
        </div>
      </div>
      <ReactTabulator
        height={window.innerHeight - 80}
        data={products}
        columns={columns}
        tooltips={true}
        layout="fitData"
        rowClick={navigateToProduct}
        options={{
          persistence: true,
          persistenceMode: 'local',
          persistenceID: 'backofficeProducts',
          persistenceLayout: true,
          movableColumns: true,
        }}
      />
    </div>
  )
}

export default ProductsPage
