import * as React from 'react'
import { Link } from 'react-router-dom'

type Props = {
  user: any
}

const User: React.FC<Props> = (props) => {
  const { user } = props
  return (
    <section className="p-2">
      <h2 className="text-3xl font-bold">お客様情報</h2>
      <table className="w-full">
        <tbody>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">ID</th>
            <td className="border w-3/5 p-1">{user.id}</td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">E-mail</th>
            <td className="border w-3/5 p-1">{user.email}</td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">組織名</th>
            <td className="border w-3/5 p-1">{user.organization}</td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">氏名</th>
            <td className="border w-3/5 p-1">
              {user.family_name} {user.given_name} ({user.family_kana}{' '}
              {user.given_kana})
            </td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">郵便番号</th>
            <td className="border w-3/5 p-1">
              {('0000000' + Number(user.postal_code)).slice(-7)}
            </td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">住所1</th>
            <td className="border w-3/5 p-1">{user.address_level1}</td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">住所2</th>
            <td className="border w-3/5 p-1">{user.address_level2}</td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">住所3</th>
            <td className="border w-3/5 p-1">{user.address_line1}</td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">住所4</th>
            <td className="border w-3/5 p-1">{user.address_line2}</td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">電話番号</th>
            <td className="border w-3/5 p-1">{user.tel}</td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">FAX</th>
            <td className="border w-3/5 p-1">{user.fax}</td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">緊急連絡先</th>
            <td className="border w-3/5 p-1">{user.emergency_tel}</td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">保有ポイント</th>
            <td className="border w-3/5 p-1">{user.holding_point}</td>
          </tr>
        </tbody>
      </table>
      <div className="py-2 flex justify-end">
        <button
          type="button"
          className="border rounded py-1 px-2 bg-white ml-1"
        >
          <span>&#129392;</span>
          <span>000</span>
        </button>
        <button
          type="button"
          className="border rounded py-1 px-2 bg-white ml-1"
        >
          <span>&#128542;</span>
          <span>000</span>
        </button>
        <button
          type="button"
          className="border rounded py-1 px-2 bg-white ml-1"
        >
          <span>&#128545;</span>
          <span>000</span>
        </button>
      </div>
      <p className="text-right">
        <Link
          to={`/users/${user.id}`}
          className="text-blue-500 hover:text-blue-800"
        >
          ユーザー情報詳細へ
        </Link>
      </p>
    </section>
  )
}

export default User
