import * as React from 'react'
import { useAuth0 } from 'react-auth0-spa'
import axios from 'axios'
import { saveAs } from 'file-saver'
import dayjs from 'dayjs'
import humanize from 'humanize-plus'

type UploadedFile = {
  id: string
  created_at: number
  name: string
  size: number
  storage: string
}

type Props = {
  orderId: string
  files: UploadedFile[]
}

const UserData: React.FC<Props> = (props) => {
  const { orderId, files } = props
  const { getTokenSilently } = useAuth0()

  const download = React.useCallback(
    async (filename: string, storage: string) => {
      const token = await getTokenSilently()
      axios.defaults.headers.common['Authorization'] = token
      axios(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/orders/${orderId}/uploaded-files/${filename}`,
        {
          method: 'get',
          responseType: storage === 'xserver' ? 'blob' : 'text',
        },
      )
        .then((res) => {
          if (res.data instanceof Blob) {
            const blob = new Blob([res.data])
            saveAs(blob, filename)
          } else {
            window.open(res.data)
          }
        })
        .catch((err) => {
          alert('入稿データが存在しないか、開くのに失敗しました')
        })
    },
    [getTokenSilently, orderId],
  )

  console.log(files)

  return (
    <div className="p-2">
      <p className="font-bold text-xl">入稿データ一覧</p>
      {files && files.length > 0 ? (
        <>
          <p className="text-xs">
            ファイルの行をクリックでダウンロードされます。
            <br />
            ファイル名はお客様が実際にアップロードしたファイル名ではありません（サーバーで書き換えています）
          </p>
          <table className="w-full select-none">
            <thead>
              <tr className="bg-gray-200">
                <th className="text-sm p-1 border">アップロード日時</th>
                <th className="text-sm p-1 border">ファイル名</th>
                <th className="text-sm p-1 border">ファイルサイズ</th>
              </tr>
            </thead>
            <tbody>
              {files
                .sort((a, b) => {
                  return b.created_at - a.created_at
                })
                .map((file) => {
                  return (
                    <tr
                      key={file.id}
                      className="hover:bg-zinc-100 active:bg-zinc-200 cursor-pointer"
                      onClick={() => {
                        download(file.name, file.storage)
                      }}
                      onKeyUp={() => {}}
                    >
                      <td className="text-sm p-1 border text-center">
                        {dayjs(file.created_at * 1000).format(
                          'YYYY-MM-DD HH:mm:ss',
                        )}
                      </td>
                      <td className="text-sm p-1 border text-center">
                        {file.name}
                      </td>
                      <td className="text-sm p-1 border text-right">
                        {humanize.fileSize(file.size)}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </>
      ) : (
        <div>入稿データはまだありません</div>
      )}
    </div>
  )
}

export default UserData
