import * as React from 'react'

type Props = {}

export const Controller = (props: Props) => {
  return (
    <div className="fixed bottom-0 left-0 w-full bg-white py-4 px-4 border-t">
      <div className="flex justify-between">
        <div className="flex">
          <div className="p-1">
            <button
              className="button rounded bg-green-500 text-white py-1 px-2 hover:bg-green-600"
              type="submit"
            >
              データを更新する
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
