import React from 'react'

type Props = {
  order: any
}

const productCategory = (order: any) => {
  switch (order.product_id) {
    case 20:
      return 'マグネット'
    case 168:
      return 'ケーキピック'
    default:
      return 'ステッカー'
  }
}

export const Yupack = (props: Props) => {
  const { order } = props
  // 変更すると事務所のPower Automateの動作に影響が出るので追加する際に順番を変更しない！！
  const data = [{ name: 'カテゴリ', value: productCategory(order) }]
  return (
    <div>
      <h2 className="text-base font-bold">
        ゆうパック自動入力用追加項目コーナー
      </h2>
      <table id="yupack" className="text-sm">
        <thead className="hidden">
          <tr>
            <th>項目名</th>
            <th>値</th>
          </tr>
        </thead>
        <tbody className="flex flex-wrap">
          {data.map((d: { name: string; value: string }) => {
            return (
              <tr key={d.name} className="border flex mr-1 mt-1">
                <td className="bg-gray-200 p-1 block">{d.name}</td>
                <td className="p-1 block">{d.value}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
