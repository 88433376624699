import React from 'react'

interface Props {
  onClose: () => void
  onSubmit: () => void
}

export const Datachecked = (props: Props) => {
  return (
    <div className="fixed h-full w-full top-0 left-0 flex items-center justify-center z-30">
      <div
        className="absolute h-full w-full top-0 left-0 bg-black opacity-50 z-40"
        onClick={props.onClose}
      />
      <div className="absolute bg-white z-50 w-1/2 min-h-64 p-4 rounded shadow">
        <h1 className="text-2xl p-2 font-bold">
          データチェック完了処理を実行しますか？
        </h1>
        <div className="p-2">
          <p>「実行する」を押すことで、</p>
          <ul className="p-2 list-disc ml-4">
            <li>お客様にデータチェック完了のお知らせが送信されます</li>
            <li>order@hitlabel.jp と Slack にも通知が送信されます</li>
            <li>注文のステータスが「データチェック完了」になります</li>
            <li>※完成イメージ画像を添付済みか確認してください</li>
          </ul>
        </div>
        <div className="flex justify-end">
          <div className="p-1">
            <button
              className="button p-2 bg-green-500 rounded text-white hover:bg-green-600"
              type="button"
              onClick={props.onSubmit}
            >
              実行する
            </button>
          </div>
          <div className="p-1">
            <button
              className="button p-2 bg-red-500 rounded text-white hover:bg-red-600"
              type="button"
              onClick={props.onClose}
            >
              キャンセル
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
