import axios from 'axios'
import NavBar from 'components/NavBar'
import Input from 'components/Form/Input'
import Loading from 'components/Loading'
import dayjs from 'dayjs'
import { Field, FieldProps, Form, Formik } from 'formik'
import * as React from 'react'
import { useAuth0 } from 'react-auth0-spa'
import { useParams } from 'react-router-dom'
import { Estimate, User } from 'types'
import Response from './response'
import { Controller } from './controller'
import { saveAs } from 'file-saver'
import { EstimateDone } from './estimate-done'
import { EstimateCancel } from './estimate-cancel'

type Params = {
  estimateNumber: string
}

const Order: React.FC = () => {
  const { estimateNumber } = useParams<Params>()
  const { getTokenSilently } = useAuth0()
  const [token, setToken] = React.useState<string | undefined>('')
  const [estimate, setEstimate] = React.useState<Estimate>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [email, setEmail] = React.useState<string>('')
  const [userName, setUserName] = React.useState<string>('未登録のお客様です')
  const [userId, setUserId] = React.useState<number | null>(null)
  const [popup, setPopup] = React.useState<string>('')

  // トークンセット
  React.useEffect(() => {
    getTokenSilently().then((t: string | undefined) => {
      setToken(t)
    })
  }, [getTokenSilently])

  React.useEffect(() => {
    if (!token) {
      return
    }
    if (!email) {
      setUserName('未登録のお客様です')
      return
    }
    setLoading(true)
    axios.defaults.headers.common['Authorization'] = token
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/user-by-email/${email}`,
      )
      .then((res) => {
        const user: User = res.data.data
        setUserId(user.id)
        setUserName(
          [
            user.organization,
            user.family_name,
            user.given_name,
            '様',
            '<',
            user.email,
            '>',
          ]
            .join(' ')
            .trim(),
        )
        setLoading(false)
      })
      .catch(() => {
        setUserName('未登録のお客様です')
        setLoading(false)
      })
  }, [email, token])

  const getEstimate = React.useCallback(() => {
    if (!token) {
      return
    }
    axios.defaults.headers.common['Authorization'] = token
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/estimates/${estimateNumber}`,
      )
      .then((res) => {
        const estimate = res.data.data.estimate
        estimate.response = JSON.parse(estimate.response)
        setEstimate(estimate)
        setEmail(estimate.email)
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [estimateNumber, token])

  const downloadPDF = React.useCallback(() => {
    if (!token) {
      return
    }
    axios.defaults.headers.common['Authorization'] = token
    axios.defaults.responseType = 'blob'
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/estimates/${estimateNumber}/pdf`,
      )
      .then((res) => {
        const blob: Blob = new Blob([res.data], {
          type: 'application/pdf',
        })
        saveAs(blob, `${estimateNumber}.pdf`)
      })
  }, [estimateNumber, token])

  // 注文情報
  React.useEffect(() => {
    getEstimate()
  }, [getEstimate])

  if (!estimate) {
    return <Loading text="見積情報読み込み中..." />
  }
  if (loading) {
    return <Loading text="データ更新中..." />
  }

  const handleDownloadPDF = () => {
    downloadPDF()
  }

  const sendEstimateDone = (message: string) => {
    setLoading(true)
    axios(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/estimates/${estimateNumber}/done`,
      {
        method: 'put',
        data: message,
      },
    )
      .then((res) => {
        getEstimate()
        setPopup('')
      })
      .catch((err) => {
        setLoading(false)
        alert('処理に失敗しました')
        setPopup('')
      })
  }

  const sendEstimateCancel = () => {
    setLoading(true)
    axios(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/estimates/${estimateNumber}/cancel`,
      {
        method: 'put',
      },
    )
      .then((res) => {
        getEstimate()
        setPopup('')
      })
      .catch((err) => {
        setLoading(false)
        alert('処理に失敗しました')
        setPopup('')
      })
  }

  return (
    <>
      <header>
        <NavBar />
      </header>
      <div className="container mx-auto mb-32">
        <Formik
          initialValues={{ estimate: estimate }}
          onSubmit={(values) => {
            setLoading(true)
            const formData = new FormData()
            const exp = dayjs(values.estimate.response.expiration_at * 1000)
            values.estimate.response.expiration_at = exp.endOf('day').unix()
            const jsonStr = JSON.stringify(values)
            formData.append('json', jsonStr)
            axios(
              `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/estimates/${estimateNumber}`,
              {
                method: 'put',
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                data: formData,
              },
            )
              .then(() => {
                getEstimate()
              })
              .catch(() => {
                setLoading(false)
                alert('更新処理に失敗しました')
              })
          }}
        >
          {(props) => {
            const { setFieldValue } = props
            const { estimate } = props.values
            return (
              <>
                <Form>
                  <h1 className="text-5xl font-bold px-4">見積もり情報詳細</h1>
                  <div className="text-sm px-4">
                    ID: {estimate.id} / 作成日時:{' '}
                    {dayjs
                      .unix(estimate.created_at)
                      .format('YYYY-MM-DD HH:mm:ss')}{' '}
                    / 更新日時:{' '}
                    {dayjs
                      .unix(estimate.updated_at)
                      .format('YYYY-MM-DD HH:mm:ss')}
                  </div>
                  <div className="p-4 text-center">
                    <div className="text-xl font-bold flex justify-between">
                      <div className="w-4/12">
                        <Field name="estimate.email">
                          {(fieldProps: FieldProps) => {
                            const { field, meta } = fieldProps
                            return (
                              <div className="px-1 w-full">
                                <Input
                                  type="text"
                                  label="依頼主様メールアドレス"
                                  placeholder="hogehoge@hogeppiyo.local"
                                  {...field}
                                />
                                {meta.touched && meta.error && meta.error}
                              </div>
                            )
                          }}
                        </Field>
                      </div>
                      <div className="w-8/12 flex items-center justify-start">
                        {userId} {userName}
                      </div>
                    </div>
                    <div className="flex justify-center items-center">
                      <Field name="estimate.title">
                        {(fieldProps: FieldProps) => {
                          const { field, meta } = fieldProps
                          return (
                            <div className="w-8/12 px-1">
                              <Input
                                type="text"
                                label="件名"
                                setFieldValue={setFieldValue}
                                {...field}
                              />
                              {meta.touched && meta.error && meta.error}
                            </div>
                          )
                        }}
                      </Field>
                      <Field name="estimate.status">
                        {(fieldProps: FieldProps) => {
                          const { field, meta } = fieldProps
                          return (
                            <div className="w-4/12 px-1">
                              <Input
                                type="select"
                                label="ステータス"
                                setFieldValue={setFieldValue}
                                {...field}
                                options={[
                                  { name: '新規', value: 'created' },
                                  { name: '作業中', value: 'in_progress' },
                                  { name: '返信済み', value: 'responsed' },
                                  { name: '注文済み', value: 'ordered' },
                                  { name: 'キャンセル', value: 'canceled' },
                                ]}
                              />
                              {meta.touched && meta.error && meta.error}
                            </div>
                          )
                        }}
                      </Field>
                    </div>
                    <div className="text-sm">
                      ※ステータスを"返信済み"にしてデータを更新するとお客様に見積もり結果が通知されます。
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-1/2 p-4">
                      <h2 className="text-3xl font-bold">依頼内容</h2>
                      <div className="flex">
                        <div className="flex-1">
                          <div className="flex flex-wrap">
                            <Field name="estimate.request">
                              {(fieldProps: FieldProps) => {
                                const { field, meta } = fieldProps
                                return (
                                  <div className="w-full px-1">
                                    <Input
                                      type="textarea"
                                      label="依頼内容"
                                      setFieldValue={setFieldValue}
                                      {...field}
                                      rows={20}
                                    />
                                    {meta.touched && meta.error && meta.error}
                                  </div>
                                )
                              }}
                            </Field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-1/2 p-4 mb-16">
                      <h2 className="text-3xl font-bold">見積もり結果</h2>
                      <Response
                        estimate={estimate}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  </div>
                  <Controller
                    onDownloadPDF={handleDownloadPDF}
                    onClick={(popupName: string) => {
                      setPopup(popupName)
                    }}
                  />
                </Form>
                {popup === 'estimateDone' && (
                  <EstimateDone
                    onClose={() => {
                      setPopup('')
                    }}
                    onSubmit={sendEstimateDone}
                    userName={userName}
                    estimateNumber={estimate.estimate_number}
                  />
                )}
                {popup === 'estimateCancel' && (
                  <EstimateCancel
                    onClose={() => {
                      setPopup('')
                    }}
                    onSubmit={sendEstimateCancel}
                  />
                )}
              </>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default Order
