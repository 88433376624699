import * as React from 'react'
import { useField, useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'

const DatePickerField: React.FC<any> = (props) => {
  const { name } = props
  const { setFieldValue } = useFormikContext()
  const [field] = useField(name)
  return (
    <DatePicker
      className="p-2 outline-none border border-solid border-gray-300 rounded w-full h-10"
      {...field}
      {...props}
      onChange={(dt: Date) => {
        let newValue: number
        if (dt) {
          newValue = Math.round(dt.getTime() / 1000)
        } else {
          newValue = 0
        }
        setFieldValue(field.name, newValue)
      }}
    />
  )
}

export default DatePickerField
