import Input from 'components/Form/Input'
import { Field, FieldProps } from 'formik'
import * as React from 'react'

type Props = {
  setFieldValue: () => any
  arrayHelpers: any
  parentIndex: number
  index: number
}

export default (props: Props) => {
  const { setFieldValue, arrayHelpers, parentIndex, index } = props
  return (
    <div className="flex">
      <div className="p-2 flex-grow">
        <Field name={`estimate.response.children[${parentIndex}].options[${index}].name`}>
          {(fieldProps: FieldProps) => {
            const { field } = fieldProps
            return (
              <div className="w-full px-1">
                <Input
                  type="text"
                  label="品名"
                  setFieldValue={setFieldValue}
                  {...field}
                />
              </div>
            )
          }}
        </Field>
      </div>
      <div className="p-2 flex-grow">
        <Field name={`estimate.response.children[${parentIndex}].options[${index}].num`}>
          {(fieldProps: FieldProps) => {
            const { field } = fieldProps
            return (
              <div className="w-full px-1">
                <Input
                  type="number"
                  label="個数"
                  setFieldValue={setFieldValue}
                  {...field}
                />
              </div>
            )
          }}
        </Field>
      </div>
      <div className="p-2 flex-grow">
        <Field
          name={`estimate.response.children[${parentIndex}].options[${index}].price`}
        >
          {(fieldProps: FieldProps) => {
            const { field } = fieldProps
            return (
              <div className="w-full px-1">
                <Input
                  type="number"
                  label="金額"
                  setFieldValue={setFieldValue}
                  {...field}
                />
              </div>
            )
          }}
        </Field>
      </div>
      <button
        type="button"
        className="p-2 border"
        onClick={() => {
          arrayHelpers.remove(index)
        }}
      >
        削除
      </button>
    </div>
  )
}
