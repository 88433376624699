import * as React from 'react'
import axios from 'axios'

export const useOrderMemo = (order_id: number) => {
  const [memos, setMemos] = React.useState([])
  const [showDeleted, setShowDeleted] = React.useState(false)
  const fetchMemo = React.useCallback(() => {
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/order-memo/${order_id}`
    axios({
      url: endpoint,
      method: 'get',
    })
      .then((res) => {
        setMemos(res.data.data)
      })
      .catch((err) => {
        console.error(err)
        window.alert('エラーが発生しました')
      })
  }, [order_id, setMemos])

  React.useEffect(() => {
    fetchMemo()
  }, [fetchMemo])

  const refetchMemo = () => {
    fetchMemo()
  }

  const createMemo = () => {
    const newEl = document.querySelector('#new-order-memo')
    if (newEl === null) {
      return
    }
    const syncSheetEl = newEl.querySelector(
      'input[name="syncSheet"]',
    ) as JSX.IntrinsicElements['input']
    if (syncSheetEl === null) {
      return
    }
    const memoEl = newEl.querySelector(
      'textarea[name="memo"]',
    ) as JSX.IntrinsicElements['textarea']
    if (memoEl === null) {
      return
    }

    const params = new URLSearchParams()
    const syncSheet = syncSheetEl.checked ? '1' : '0'
    params.append('sync_sheet', syncSheet)
    params.append('memo', String(memoEl.value))

    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/order-memo/${order_id}`
    axios({
      url: endpoint,
      method: 'post',
      data: params,
    })
      .then(() => {
        syncSheetEl.checked = false
        refetchMemo()
      })
      .catch((err) => {
        window.alert('エラーが発生しました')
        console.error(err)
      })
  }

  const updateMemo = (memoId: string) => {
    const targetEl = document.querySelector(`#${memoId}`)
    if (targetEl === null) {
      return
    }
    const syncSheetEl = targetEl.querySelector(
      'input[name="syncSheet"]',
    ) as JSX.IntrinsicElements['input']
    if (syncSheetEl === null) {
      return
    }
    const memoEl = targetEl.querySelector(
      'textarea[name="memo"]',
    ) as JSX.IntrinsicElements['textarea']
    if (memoEl === null) {
      return
    }

    const params = new URLSearchParams()
    const syncSheet = syncSheetEl.checked ? '1' : '0'
    params.append('sync_sheet', syncSheet)
    params.append('memo', String(memoEl.value))

    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/order-memo/${memoId}`
    axios({
      url: endpoint,
      method: 'put',
      data: params,
    })
      .then(() => {
        refetchMemo()
      })
      .catch((err) => {
        window.alert('エラーが発生しました')
        console.error(err)
      })
  }

  const deleteMemo = (memoId: string) => {
    const ok = window.confirm('よろしいですか？')
    if (!ok) {
      return
    }
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/order-memo/${memoId}`
    axios({
      url: endpoint,
      method: 'delete',
    })
      .then(() => {
        refetchMemo()
      })
      .catch((err) => {
        console.error(err)
        window.alert('エラーが発生しました')
      })
  }
  return {
    memos,
    refetchMemo,
    createMemo,
    updateMemo,
    deleteMemo,
    showDeleted,
    setShowDeleted,
  }
}
