import * as React from 'react'
import { useAuth0 } from 'react-auth0-spa'
import hitkun from 'images/hitkun_sad.png'

const UnauthorizedPage: React.FC = () => {
  const { isAuthenticated, logout } = useAuth0()
  return (
    <div className="fixed w-screen h-screen flex justify-center top-0 left-0 z-10">
      <div className="absolute opacity-75 w-full h-full bg-white z-20" />
      <div className="flex flex-col justify-center items-center z-50">
        <img src={hitkun} alt="しょぼん..." className="h-48" />
        <div className="text-4xl font-bold p-8 text-center">
          ログインしたアカウントは権限がありません
        </div>
        {isAuthenticated && (
          <button className="border rounded px-4 py-2" onClick={() => logout()}>
            ログアウト
          </button>
        )}
      </div>
    </div>
  )
}

export default UnauthorizedPage
