import React from 'react'
import { QRCodeSVG } from 'qrcode.react'
import { productNameById } from 'utils/product'
import dayjs from 'dayjs'

type Props = {
  order: any
  user: any
  purchase: any
  products: any
}

export const PrintPage = (props: Props) => {
  const { order, user, purchase, products } = props
  return (
    <div className="hidden print:block">
      <div className="flex justify-between py-4">
        <div>
          <div className="text-xl font-bold">
            <span className="inline-block w-24">注文番号: </span>
            <span className="inline-block">{order.order_id}</span>
          </div>
          <div>
            <span className="inline-block w-24">商品名: </span>
            <span className="inline-block">{order.name}</span>
          </div>
          <div>
            <span className="inline-block w-24">枚数: </span>
            <span className="inline-block">{order.num}</span>
          </div>
          <div>
            <span className="inline-block w-24">発送予定日: </span>
            <span className="inline-block">
              {dayjs.unix(order.shipping_date).format('YYYY/MM/DD')}
            </span>
          </div>
          <div>
            <span className="inline-block w-24">控え発送: </span>
            <span className="inline-block">
              {productNameById(products, 'sendCopy', order.send_copy)}
            </span>
          </div>
          <div>
            <span className="inline-block w-24">時間指定: </span>
            <span className="inline-block">{purchase.delivery_time}</span>
          </div>
          <div>
            <span className="inline-block w-24">備考 :</span>
            <span className="inline-block">{purchase.note}</span>
          </div>
        </div>
        <QRCodeSVG
          value={`https://backoffice.hitlabel.hit.works/orders/${order.order_id}`}
        />
      </div>
      <div className="flex py-4">
        <table className="table-auto w-1/2">
          <tbody>
            <tr>
              <th colSpan={2}>配送元</th>
            </tr>
            <tr>
              <th>組織名</th>
              <td>{purchase.src_organization}</td>
            </tr>
            <tr>
              <th>名称</th>
              <td>
                {[purchase.src_family_name, purchase.src_given_name].join(' ')}
              </td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>{purchase.src_tel}</td>
            </tr>
            <tr>
              <th>郵便番号</th>
              <td>
                {('0000000' + Number(purchase.src_postal_code)).slice(-7)}
              </td>
            </tr>
            <tr>
              <th>住所1</th>
              <td>{purchase.src_address_level1}</td>
            </tr>
            <tr>
              <th>住所2</th>
              <td>{purchase.src_address_level2}</td>
            </tr>
            <tr>
              <th>住所3</th>
              <td>{purchase.src_address_line1}</td>
            </tr>
            <tr>
              <th>住所4</th>
              <td>{purchase.src_address_line2}</td>
            </tr>
          </tbody>
        </table>
        <table className="table-auto w-1/2">
          <tbody>
            <tr>
              <th colSpan={2}>配送先</th>
            </tr>
            <tr>
              <th>組織名</th>
              <td>{purchase.dest_organization}</td>
            </tr>
            <tr>
              <th>氏名</th>
              <td>
                {[purchase.dest_family_name, purchase.dest_given_name].join(
                  ' ',
                )}
              </td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>{purchase.dest_tel}</td>
            </tr>
            <tr>
              <th>郵便番号</th>
              <td>
                {('0000000' + Number(purchase.dest_postal_code)).slice(-7)}
              </td>
            </tr>
            <tr>
              <th>住所1</th>
              <td>{purchase.dest_address_level1}</td>
            </tr>
            <tr>
              <th>住所2</th>
              <td>{purchase.dest_address_level2}</td>
            </tr>
            <tr>
              <th>住所3</th>
              <td>{purchase.dest_address_line1}</td>
            </tr>
            <tr>
              <th>住所4</th>
              <td>{purchase.dest_address_line2}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="py-4">
        <table className="table-auto w-1/2">
          <thead>
            <tr>
              <th colSpan={2}>顧客情報</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>E-mail</th>
              <td>{user.email}</td>
            </tr>
            <tr>
              <th>組織名</th>
              <td>{user.organization}</td>
            </tr>
            <tr>
              <th>氏名</th>
              <td>
                {user.family_name} {user.given_name} ({user.family_kana}{' '}
                {user.given_kana})
              </td>
            </tr>
            <tr>
              <th>郵便番号</th>
              <td>{('0000000' + Number(user.postal_code)).slice(-7)}</td>
            </tr>
            <tr>
              <th>住所1</th>
              <td>{user.address_level1}</td>
            </tr>
            <tr>
              <th>住所2</th>
              <td>{user.address_level2}</td>
            </tr>
            <tr>
              <th>住所3</th>
              <td>{user.address_line1}</td>
            </tr>
            <tr>
              <th>住所4</th>
              <td>{user.address_line2}</td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>{user.tel}</td>
            </tr>
            <tr>
              <th>FAX</th>
              <td>{user.fax}</td>
            </tr>
            <tr>
              <th>緊急連絡先</th>
              <td>{user.emergency_tel}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
