import * as React from 'react'
import DatePickerField from './DatePickerField'

export type SelectOption = {
  name: string
  value: string | number
}

type Props = {
  name: string
  type: string
  label: string
  value?: string | number
  disabled?: boolean
  placeholder?: string
  autoComplete?: string
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void
  helpMessage?: string
  errorMessage?: string
  required?: boolean
  options?: SelectOption[]
  rows?: number
  defaultValue?: string
  step?: string
}

const Field: React.FC<Props> = (props) => {
  let {
    name,
    type,
    label,
    options,
    placeholder,
    autoComplete,
    helpMessage,
    errorMessage,
    setFieldValue,
    ...rest
  } = props
  let input: any
  if (type === 'text') {
    input = (
      <div className="relative py-2 block flex-grow">
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          autoComplete={autoComplete}
          className="p-2 outline-none border border-solid border-gray-300 rounded w-full h-10 appearance-none"
          {...rest}
        />
        <label className="absolute top-0 left-0 text-sm ml-2 bg-white text-gray-500 font-bold">
          {label}
        </label>
        <div className="absolute right-0 bottom-0 text-xs">{helpMessage}</div>
        <div className="absolute right-0 bottom-0 tetx-xs">{errorMessage}</div>
      </div>
    )
  } else if (type === 'postal') {
    const { ...restrest } = rest
    input = (
      <div className="relative py-2 block flex-grow">
        <input
          type="text"
          name={name}
          placeholder={placeholder}
          autoComplete={autoComplete}
          className="p-2 outline-none border border-solid border-gray-300 rounded w-full h-10 appearance-none"
          {...restrest}
        />
        <label className="absolute top-0 left-0 text-sm ml-2 bg-white text-gray-500 font-bold">
          {label}
        </label>
        <div className="absolute right-0 bottom-0 text-xs">{helpMessage}</div>
        <div className="absolute right-0 bottom-0 tetx-xs">{errorMessage}</div>
      </div>
    )
  } else if (type === 'number') {
    input = (
      <div className="relative py-2 block">
        <input
          type="number"
          name={name}
          placeholder={placeholder}
          autoComplete={autoComplete}
          className="p-2 outline-none border border-solid border-gray-300 rounded w-full h-10 appearance-none disabled:bg-gray-100"
          {...rest}
        />
        <label className="absolute top-0 left-0 text-sm ml-2 bg-white text-gray-500 font-bold">
          {label}
        </label>
        <div className="absolute right-0 bottom-0 text-xs">{helpMessage}</div>
        <div className="absolute right-0 bottom-0 tetx-xs">{errorMessage}</div>
      </div>
    )
  } else if (type === 'datetime') {
    input = (
      <div className="relative py-2 block">
        <DatePickerField
          name={name}
          selected={
            (Number(props.value) && new Date(Number(props.value) * 1000)) ||
            null
          }
          dateFormat="yyyy/MM/dd HH:mm:ss"
          {...rest}
        />
        <label className="absolute top-0 left-0 text-sm ml-2 bg-white text-gray-500 font-bold">
          {label}
        </label>
        <div className="absolute right-0 bottom-0 text-xs">{helpMessage}</div>
        <div className="absolute right-0 bottom-0 tetx-xs">{errorMessage}</div>
      </div>
    )
  } else if (type === 'date') {
    input = (
      <div className="relative py-2 block">
        <DatePickerField
          name={name}
          selected={
            (Number(props.value) && new Date(Number(props.value) * 1000)) ||
            null
          }
          dateFormat="yyyy/MM/dd"
          {...rest}
        />
        <label className="absolute top-0 left-0 text-sm ml-2 bg-white text-gray-500 font-bold">
          {label}
        </label>
        <div className="absolute right-0 bottom-0 text-xs">{helpMessage}</div>
        <div className="absolute right-0 bottom-0 tetx-xs">{errorMessage}</div>
      </div>
    )
  } else if (type === 'select') {
    if (!options) {
      return <div />
    }
    const optionsNode = options.map((o) => {
      return (
        <option key={o.value} value={o.value}>
          {o.name}
        </option>
      )
    })
    input = (
      <div className="relative py-2 block">
        <select
          name={name}
          autoComplete={autoComplete}
          className="p-2 outline-none border border-solid border-gray-300 rounded w-full h-10 bg-white"
          {...rest}
          onChange={(e) => {
            // TODO: valueに数字以外が入ってくるときの事を考える
            if (setFieldValue) {
              let val = e.target.value
              if (!isNaN(Number(val))) {
                setFieldValue(name, Number(val))
                return
              }
              if (e.target.value === 'false') {
                setFieldValue(name, false)
                return
              }
              if (e.target.value === 'true') {
                setFieldValue(name, true)
                return
              }
              setFieldValue(name, e.target.value)
              return
            }
          }}
        >
          {optionsNode}
        </select>
        <label className="absolute top-0 left-0 text-sm ml-2 bg-white text-gray-500 font-bold">
          {label}
        </label>
        <div className="absolute right-0 bottom-0 text-xs">{helpMessage}</div>
        <div className="absolute right-0 bottom-0 tetx-xs">{errorMessage}</div>
      </div>
    )
  } else if (type === 'textarea') {
    input = (
      <div className="relative py-2 block h-full w-full">
        <textarea
          name={name}
          placeholder={placeholder}
          autoComplete={autoComplete}
          className="p-2 outline-none border border-solid border-gray-300 rounded w-full h-auto font-mono appearance-none h-full w-full"
          {...rest}
        />
        <label className="absolute top-0 left-0 text-sm ml-2 bg-white text-gray-500 font-bold">
          {label}
        </label>
        <div className="absolute right-0 bottom-0 text-xs">{helpMessage}</div>
        <div className="absolute right-0 bottom-0 tetx-xs">{errorMessage}</div>
      </div>
    )
  }
  return input
}

export default Field
