import React from 'react'
import { useOrderMemo } from 'hooks'
import dayjs from 'dayjs'

interface Props {
  orderId: number
}

export const Memo = (props: Props) => {
  const { orderId } = props
  const {
    memos,
    createMemo,
    updateMemo,
    deleteMemo,
    showDeleted,
    setShowDeleted,
  } = useOrderMemo(orderId)

  return (
    <div className="my-4">
      <h2>
        <strong className="text-2xl">メモ</strong>
      </h2>
      <ul className="py-2">
        <li>
          ※<strong>SS同期</strong>にチェックが入っているメモがGoogle Spread
          Sheetの工程管理表データシートに同期されます。Google Spread
          Sheetへの同期は1つだけです。
        </li>
      </ul>
      <label className="flex items-center justify-end">
        <input
          type="checkbox"
          name="show-deleted"
          onChange={() => {
            setShowDeleted(!showDeleted)
          }}
        />
        <span className="px-1 text-sm font-bold text-gray-700">
          削除済みのものを表示する
        </span>
      </label>
      <table className="w-full text-sm table-fixed">
        <thead>
          <tr>
            <th className="border p-1 font-bold">
              投稿日時
              <br />
              更新日時
            </th>
            <th className="border p-1 font-bold w-32">投稿者ID</th>
            <th className="border p-1 font-bold">SS同期</th>
            <th className="border p-1 font-bold w-1/2">内容</th>
            <th className="border p-1 font-bold"></th>
          </tr>
          <tr id="new-order-memo">
            <td className="border p-1 text-center">新規追加</td>
            <td className="border"></td>
            <td className="border-2 p-1 text-center">
              <input type="radio" value="1" name="syncSheet" />
            </td>
            <td className="border-2">
              <textarea
                name="memo"
                className="w-full h-full outline-none p-1"
              />
            </td>
            <td className="border-2 text-center">
              <button
                type="button"
                className="text-white bg-green-500 p-1 rounded hover:bg-green-600 active:bg-green-700 focus:outline-none"
                onClick={createMemo}
              >
                追加
              </button>
            </td>
          </tr>
        </thead>
        <tbody>
          {memos.map((memo: any) => {
            if (memo.deleted_at > 0 && !showDeleted) {
              return null
            }
            const rowClassName =
              memo.deleted_at > 0 ? 'bg-gray-400' : 'bg-white'
            return (
              <tr id={memo.id} key={memo.id} className={rowClassName}>
                <td className="border p-1 text-center text-xs">
                  {dayjs.unix(memo.created_at).format('YYYY-MM-DD HH:mm:ss')}
                  <br />
                  {dayjs.unix(memo.updated_at).format('YYYY-MM-DD HH:mm:ss')}
                </td>
                <td className="border p-1 overflow-hidden truncate text-center text-xs">
                  ...{memo.posted_by.slice(-8)}
                </td>
                <td className="border-2 p-1 text-center">
                  <input
                    type="radio"
                    name="syncSheet"
                    defaultChecked={memo.sync_sheet}
                  />
                </td>
                <td className="border-2 p-1">
                  <textarea
                    name="memo"
                    className="w-full h-full outline-none p-1 bg-transparent"
                    defaultValue={memo.memo}
                  />
                </td>
                <td className="border-2 text-center">
                  {memo.deleted_at === 0 ? (
                    <>
                      <button
                        type="button"
                        className="text-white bg-green-500 p-1 rounded hover:bg-green-600 active:bg-green-700 focus:outline-none"
                        onClick={() => {
                          updateMemo(memo.id)
                        }}
                      >
                        更新
                      </button>
                      <button
                        type="button"
                        className="text-white bg-red-500 p-1 rounded hover:bg-red-600 active:bg-red-700 focus:outline-none"
                        onClick={() => {
                          deleteMemo(memo.id)
                        }}
                      >
                        削除
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
