import * as React from 'react'
import Input from 'components/Form/Input'
import { Field, FieldProps, FieldArray } from 'formik'
import Item from './response-item'
import { Estimate } from 'types'
import Price from './price'

type Props = {
  index: number
  setFieldValue: any
  arrayHelpers: any
  estimate: Estimate
}

export default (props: Props) => {
  const { index, setFieldValue, arrayHelpers, estimate } = props
  return (
    <div className="py-4 px-1">
      <div className="border border-gray-300 rounded">
        <div className="flex p-2 items-center">
          <Field name={`estimate.response.children[${index}].no`}>
            {(fieldProps: FieldProps) => {
              const { field, meta } = fieldProps
              return (
                <div className="w-3/12 px-1">
                  <Input
                    type="text"
                    label="小番号"
                    setFieldValue={setFieldValue}
                    {...field}
                    rows={20}
                  />
                  {meta.touched && meta.error && meta.error}
                </div>
              )
            }}
          </Field>
          <Field name={`estimate.response.children[${index}].name`}>
            {(fieldProps: FieldProps) => {
              const { field, meta } = fieldProps
              return (
                <div className="w-9/12 px-1">
                  <Input
                    type="text"
                    label="パターン名"
                    setFieldValue={setFieldValue}
                    {...field}
                  />
                  {meta.touched && meta.error && meta.error}
                </div>
              )
            }}
          </Field>
        </div>
        <div className="flex">
          <div className="p-2 flex-grow">
            <Field name={`estimate.response.children[${index}].main.name`}>
              {(fieldProps: FieldProps) => {
                const { field, meta } = fieldProps
                return (
                  <div className="w-full px-1">
                    <Input
                      type="text"
                      label="品名"
                      setFieldValue={setFieldValue}
                      {...field}
                    />
                    {meta.touched && meta.error && meta.error}
                  </div>
                )
              }}
            </Field>
          </div>
          <div className="p-2 flex-grow">
            <Field name={`estimate.response.children[${index}].main.num`}>
              {(fieldProps: FieldProps) => {
                const { field, meta } = fieldProps
                return (
                  <div className="w-full px-1">
                    <Input
                      type="number"
                      label="枚数"
                      setFieldValue={setFieldValue}
                      {...field}
                    />
                    {meta.touched && meta.error && meta.error}
                  </div>
                )
              }}
            </Field>
          </div>
          <div className="p-2 flex-grow">
            <Field
              name={`estimate.response.children[${index}].main.unit_price`}
            >
              {(fieldProps: FieldProps) => {
                const { field, meta } = fieldProps
                return (
                  <div className="w-full px-1">
                    <Input
                      type="number"
                      step="0.1"
                      label="単価"
                      setFieldValue={setFieldValue}
                      {...field}
                    />
                    {meta.touched && meta.error && meta.error}
                  </div>
                )
              }}
            </Field>
          </div>
          <div className="p-2 flex-grow">
            <Field name={`estimate.response.children[${index}].main.price`}>
              {(fieldProps: FieldProps) => {
                const { field, meta } = fieldProps
                return (
                  <div className="w-full px-1">
                    <Input
                      type="number"
                      label="金額"
                      setFieldValue={setFieldValue}
                      {...field}
                    />
                    {meta.touched && meta.error && meta.error}
                  </div>
                )
              }}
            </Field>
          </div>
        </div>
        <div className="p-2">
          <Field
            name={`estimate.response.children[${index}].main.specification`}
          >
            {(fieldProps: FieldProps) => {
              const { field, meta } = fieldProps
              return (
                <div className="w-full px-1 font-mono">
                  <Input
                    type="textarea"
                    label="仕様"
                    setFieldValue={setFieldValue}
                    {...field}
                    rows={5}
                  />
                  {meta.touched && meta.error && meta.error}
                </div>
              )
            }}
          </Field>
        </div>
        <hr className="p-2" />
        <FieldArray
          name={`estimate.response.children[${index}].options`}
          render={(arrayHelpers) => {
            const optionNum = estimate.response.children[index].options.length
            if (optionNum === 0) {
              return (
                <div className="flex justify-center items-center">
                  <button
                    type="button"
                    className="border rounded py-1 px-4"
                    onClick={() => {
                      arrayHelpers.push({ name: '', num: 1, price: 0 })
                    }}
                  >
                    項目追加
                  </button>
                </div>
              )
            }
            const options = estimate.response.children[index].options.map(
              (o, i) => {
                return (
                  <Item
                    key={i}
                    setFieldValue={setFieldValue}
                    arrayHelpers={arrayHelpers}
                    parentIndex={index}
                    index={i}
                  />
                )
              },
            )
            return (
              <div>
                {options}
                <div className="flex justify-center items-center py-4">
                  <button
                    type="button"
                    className="border rounded py-1 px-4"
                    onClick={() => {
                      arrayHelpers.push({ name: '', num: 1, price: 0 })
                    }}
                  >
                    項目追加
                  </button>
                </div>
              </div>
            )
          }}
        />
        <hr />
        <div className="flex flex-col justify-between items-end p-2">
          <Price index={index} />
          <button
            type="button"
            className="border rounded py-1 px-4"
            onClick={() => {
              arrayHelpers.remove(index)
            }}
          >
            小見積もり削除
          </button>
        </div>
      </div>
    </div>
  )
}
