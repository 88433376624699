import axios from 'axios'
import Loading from 'components/Loading'
import * as React from 'react'
import { useAuth0 } from 'react-auth0-spa'
import { useParams } from 'react-router-dom'
import yamatologo from 'images/yamato-logo.svg'
import yuubinlogo from 'images/yuubin-logo.svg'
import unknownimg from 'images/unknown.svg'
import dayjs from 'dayjs'

type Params = {
  date: string
}

const Page: React.FC = () => {
  const { date } = useParams<Params>()
  const { getTokenSilently } = useAuth0()
  const [token, setToken] = React.useState<string | undefined>('')
  const [loading, setLoading] = React.useState<boolean>(false)
  const [shipments, setShipments] = React.useState<any[]>([])

  // トークンセット
  React.useEffect(() => {
    getTokenSilently().then((t: string | undefined) => {
      setToken(t)
    })
  }, [getTokenSilently])

  React.useEffect(() => {
    if (!token) {
      return
    }
    setLoading(true)
    axios.defaults.headers.common['Authorization'] = token
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/shipments/${date}`,
      )
      .then((res) => {
        const data = res.data.data.filter((d: any) => {
          if (d.tracking_number !== '') {
            return true
          }
          return false
        })
        setShipments(data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [date, token])

  const service = (s: string) => {
    switch (s) {
      case 'yamato_regular':
        return [yamatologo, '発払い']
      case 'yamato_collect':
        return [yamatologo, 'コレクト']
      case 'yamato_taqbin':
        return [yamatologo, 'コンパクト']
      case 'yamato_taqbin_collect':
        return [yamatologo, 'コンパクトコレクト']
      case 'yamato_nekopos':
        return [yamatologo, 'ネコポス']
      case 'yuupack_regular':
        return [yuubinlogo, 'ゆうパック']
      case 'yuupack_fresh':
        return [yuubinlogo, 'ゆうパック']
      case 'yuupack_frozen':
        return [yuubinlogo, 'ゆうパック']
      case 'yuupacket_regular':
        return [yuubinlogo, 'ゆうパケット']
      default:
        return [unknownimg, '']
    }
  }

  if (loading) {
    return <Loading text="読み込み中..." />
  }

  return (
    <main className="w-[1024x] max-w-[1024px] mx-auto relative">
      <h1 className="items-baseline text-center mb-2 flex items-center justify-between">
        <span className="text-xl font-bold tracking-loose">
          {date} の出荷リスト ({shipments.length}件)
        </span>
        <span className="text-xs">
          {dayjs().format('YYYY-MM-DD HH:mm:ss')}時点
        </span>
      </h1>
      <div className="grid grid-cols-[24px_140px_140px_1fr_1fr] gap-2 text-xs border-b-2 border-l border-r border-t bg-stone-200">
        <div className="text-center p-1 h-[24px] border-r"></div>
        <div className="text-center p-1 text-stone-600 border-r">
          配送サービス
        </div>
        <div className="text-center p-1 text-stone-600 border-r">伝票番号</div>
        <div className="text-center p-1 text-stone-600 border-r">荷主</div>
        <div className="text-center p-1 text-stone-600">配送先</div>
      </div>
      {shipments.map((shipment) => {
        const [logo, serviceName] = service(shipment.service)
        return (
          <div
            key={shipment.id}
            className="grid grid-cols-[24px_140px_140px_1fr_1fr] gap-2 text-xs border-t border-l border-r last:border-b print:break-inside-avoid"
          >
            <div className="border-r flex items-center justify-center">
              <div className="h-[16px] w-[16px] mx-auto border border-black"></div>
            </div>
            <div className="flex items-center justify-center gap-1 border-r p-1">
              <img src={logo} alt={serviceName} width={16} height={16} />
              <span>{serviceName}</span>
            </div>
            <div className="whitespace-nowrap flex items-center justify-center border-r p-1">
              {shipment.tracking_number.replace(
                /(\d{4})(\d{4})(\d{4})/,
                '$1-$2-$3',
              )}
            </div>
            <div className="break-all whitespace-break-spaces border-r p-1">
              {[shipment.from_company, shipment.from_full_name].join(' ')} 様
            </div>
            <div className="p-1">
              {[shipment.to_company, shipment.to_full_name]} 様
            </div>
          </div>
        )
      })}
    </main>
  )
}

export default Page
