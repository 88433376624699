import React from 'react'
import ReactDOM from 'react-dom'
import 'react-tabulator/lib/styles.css'
import 'react-tabulator/lib/css/tabulator.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'styles/index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Auth0Provider } from './react-auth0-spa'
import config from './auth_config.json'
import history from './utils/history'

const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  )
}

const clientId: string =
  process.env.REACT_APP_AUTH0_CLIENT_ID || 'NIAYlhc90j2MorNGXAYdEMB2UsvMeYj7'

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={clientId}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    audience={config.audience}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root'),
)

serviceWorker.unregister()
