import * as React from 'react'
import { IconAdd } from 'components/Icons'

type Props = {
  type: 'button' | 'submit' | 'reset' | undefined
  onClick?: () => void
}

export const ButtonAdd = (props: Props) => {
  const { type, ...rest } = props
  const [color, setColor] = React.useState('#15c1ab')
  const handleOver = () => {
    setColor('#109380')
  }
  const handleLeave = () => {
    setColor('#15c1ab')
  }
  const handleDown = () => {
    setColor('#0b6558')
  }
  const handleUp = () => {
    setColor('#109380')
  }
  return (
    <button
      type={type}
      {...rest}
      onMouseOver={handleOver}
      onMouseLeave={handleLeave}
      onMouseDown={handleDown}
      onMouseUp={handleUp}
      className="focus:outline-none"
    >
      <IconAdd color={color} size={32} />
    </button>
  )
}
