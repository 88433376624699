import * as React from 'react'
import madanai from 'images/madanai.svg'
import { useDropzone } from 'react-dropzone'

type Props = {
  src?: string
  className?: string
  onDrop: (acceptedFiles: File[]) => void
}

const SampleImage: React.FC<Props> = (props) => {
  let { src, onDrop } = props
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  if (src === 'madanai') {
    src = madanai
  }

  if (src === '') {
    return (
      <div className="p-2">
        <div className="w-full h-64 flex justify-center items-center text-xl font-bold bg-gray-200">
          画像読込中...
        </div>
      </div>
    )
  }

  return (
    <div className="p-2">
      <figure className="relative group">
        <img src={src} alt="商品参考画像" className="w-full h-auto" />
        <div
          {...getRootProps()}
          className="opacity-75 absolute top-0 left-0 w-full h-full justify-center items-center bg-gray-500 hidden group-hover:flex cursor-pointer hover:bg-gray-800"
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="text-center text-2xl font-bold text-white">
              ここにドロップしてください
            </p>
          ) : (
            <p className="text-center text-2xl font-bold text-white">
              ここにドラッグ＆ドロップまたは
              <br />
              クリックで製品イメージ画像を添付出来ます
            </p>
          )}
        </div>
      </figure>
    </div>
  )
}

export default SampleImage
