import axios from 'axios'
import Input from 'components/Form/Input'
import Loading from 'components/Loading'
import dayjs from 'dayjs'
import { Field, FieldProps, Form, Formik } from 'formik'
import * as React from 'react'
import { useAuth0 } from 'react-auth0-spa'
import { useParams } from 'react-router-dom'
import { TypeProduct } from 'types'
import { Controller } from './controller'

type Params = {
  productId: string
}

const Product: React.FC = () => {
  const { productId } = useParams<Params>()
  const { getTokenSilently } = useAuth0()
  const [token, setToken] = React.useState<string | undefined>('')
  const [product, setProduct] = React.useState<TypeProduct>()
  const [loading, setLoading] = React.useState<boolean>(false)

  // トークンセット
  React.useEffect(() => {
    getTokenSilently().then((t: string | undefined) => {
      setToken(t)
    })
  }, [getTokenSilently])

  const getProduct = React.useCallback(() => {
    if (!token) {
      return
    }
    if (!productId) {
      return
    }
    axios.defaults.headers.common['Authorization'] = token
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/products/${productId}`,
      )
      .then((res) => {
        const product = res.data.data
        setProduct(product)
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [productId, token])

  React.useEffect(() => {
    getProduct()
  }, [getProduct])

  if (!product) {
    return <Loading text="商品情報読み込み中..." />
  }
  if (loading) {
    return <Loading text="データ更新中..." />
  }

  return (
    <div className="container mx-auto mb-32">
      <Formik
        initialValues={{ product: product }}
        onSubmit={(values) => {
          // setLoading(true)
          const formData = new FormData()
          const data = values.product
          data.setting = JSON.stringify(JSON.parse(data.setting))
          const jsonStr = JSON.stringify(data)
          formData.append('json', jsonStr)
          axios(
            `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/products/${productId}`,
            {
              method: 'put',
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: formData,
            },
          )
            .then((res) => {
              setProduct(res.data.data)
            })
            .catch(() => {
              setLoading(false)
              alert('更新処理に失敗しました')
            })
        }}
      >
        {(props) => {
          const { setFieldValue } = props
          const { product } = props.values
          return (
            <>
              <Form>
                <h1 className="text-5xl font-bold px-4">商品情報詳細</h1>
                <div className="text-sm px-4">
                  ID: {product.id} / 作成日時:{' '}
                  {dayjs.unix(product.created_at).format('YYYY-MM-DD HH:mm:ss')}{' '}
                  / 更新日時:{' '}
                  {dayjs.unix(product.updated_at).format('YYYY-MM-DD HH:mm:ss')}
                </div>

                <div className="flex">
                  <div className="w-1/2 p-4">
                    <h2 className="text-3xl font-bold">商品情報</h2>
                    <div className="flex">
                      <div className="flex-1">
                        <div className="flex flex-wrap">
                          <Field name="product.id">
                            {(fieldProps: FieldProps) => {
                              const { field, meta } = fieldProps
                              return (
                                <div className="w-full px-1">
                                  <Input
                                    type="text"
                                    label="ID"
                                    setFieldValue={setFieldValue}
                                    {...field}
                                  />
                                  {meta.touched && meta.error && meta.error}
                                </div>
                              )
                            }}
                          </Field>

                          <Field name="product.created_at">
                            {(fieldProps: FieldProps) => {
                              const { field, meta } = fieldProps
                              return (
                                <div className="w-full px-1">
                                  <Input
                                    type="text"
                                    label="作成日時(UNIXTIME)"
                                    setFieldValue={setFieldValue}
                                    {...field}
                                  />
                                  {meta.touched && meta.error && meta.error}
                                </div>
                              )
                            }}
                          </Field>

                          <Field name="product.updated_at">
                            {(fieldProps: FieldProps) => {
                              const { field, meta } = fieldProps
                              return (
                                <div className="w-full px-1">
                                  <Input
                                    type="text"
                                    label="更新日時(UNIXTIME)"
                                    setFieldValue={setFieldValue}
                                    {...field}
                                  />
                                  {meta.touched && meta.error && meta.error}
                                </div>
                              )
                            }}
                          </Field>

                          <Field name="product.deleted_at">
                            {(fieldProps: FieldProps) => {
                              const { field, meta } = fieldProps
                              return (
                                <div className="w-full px-1">
                                  <Input
                                    type="text"
                                    label="削除日時(UNIXTIME)"
                                    setFieldValue={setFieldValue}
                                    {...field}
                                  />
                                  {meta.touched && meta.error && meta.error}
                                </div>
                              )
                            }}
                          </Field>

                          <Field name="product.name">
                            {(fieldProps: FieldProps) => {
                              const { field, meta } = fieldProps
                              return (
                                <div className="w-full px-1">
                                  <Input
                                    type="text"
                                    label="名前"
                                    setFieldValue={setFieldValue}
                                    {...field}
                                  />
                                  {meta.touched && meta.error && meta.error}
                                </div>
                              )
                            }}
                          </Field>

                          <Field name="product.name_detail">
                            {(fieldProps: FieldProps) => {
                              const { field, meta } = fieldProps
                              return (
                                <div className="w-full px-1">
                                  <Input
                                    type="text"
                                    label="名前詳細"
                                    setFieldValue={setFieldValue}
                                    {...field}
                                  />
                                  {meta.touched && meta.error && meta.error}
                                </div>
                              )
                            }}
                          </Field>

                          <Field name="product.product_type">
                            {(fieldProps: FieldProps) => {
                              const { field, meta } = fieldProps
                              return (
                                <div className="w-full px-1">
                                  <Input
                                    type="text"
                                    label="商品種別"
                                    setFieldValue={setFieldValue}
                                    {...field}
                                  />
                                  {meta.touched && meta.error && meta.error}
                                </div>
                              )
                            }}
                          </Field>

                          <Field name="product.category">
                            {(fieldProps: FieldProps) => {
                              const { field, meta } = fieldProps
                              return (
                                <div className="w-full px-1">
                                  <Input
                                    type="text"
                                    label="カテゴリ"
                                    setFieldValue={setFieldValue}
                                    {...field}
                                  />
                                  {meta.touched && meta.error && meta.error}
                                </div>
                              )
                            }}
                          </Field>

                          <Field name="product.price">
                            {(fieldProps: FieldProps) => {
                              const { field, meta } = fieldProps
                              return (
                                <div className="w-full px-1">
                                  <Input
                                    type="text"
                                    label="価格"
                                    setFieldValue={setFieldValue}
                                    {...field}
                                  />
                                  {meta.touched && meta.error && meta.error}
                                </div>
                              )
                            }}
                          </Field>

                          <Field name="product.description">
                            {(fieldProps: FieldProps) => {
                              const { field, meta } = fieldProps
                              return (
                                <div className="w-full px-1">
                                  <Input
                                    type="textarea"
                                    label="概要"
                                    setFieldValue={setFieldValue}
                                    {...field}
                                    rows={5}
                                  />
                                  {meta.touched && meta.error && meta.error}
                                </div>
                              )
                            }}
                          </Field>

                          <Field name="product.visible">
                            {(fieldProps: FieldProps) => {
                              const { field, meta } = fieldProps
                              return (
                                <div className="w-full px-1">
                                  <Input
                                    type="text"
                                    label="表示"
                                    setFieldValue={setFieldValue}
                                    {...field}
                                  />
                                  {meta.touched && meta.error && meta.error}
                                </div>
                              )
                            }}
                          </Field>

                          <Field name="product.sort_order">
                            {(fieldProps: FieldProps) => {
                              const { field, meta } = fieldProps
                              return (
                                <div className="w-full px-1">
                                  <Input
                                    type="text"
                                    label="並び順"
                                    setFieldValue={setFieldValue}
                                    {...field}
                                  />
                                  {meta.touched && meta.error && meta.error}
                                </div>
                              )
                            }}
                          </Field>

                          <Field name="product.cut_rate">
                            {(fieldProps: FieldProps) => {
                              const { field, meta } = fieldProps
                              return (
                                <div className="w-full px-1">
                                  <Input
                                    type="text"
                                    label="カット割合"
                                    setFieldValue={setFieldValue}
                                    {...field}
                                  />
                                  {meta.touched && meta.error && meta.error}
                                </div>
                              )
                            }}
                          </Field>

                          <Field name="product.paper_price">
                            {(fieldProps: FieldProps) => {
                              const { field, meta } = fieldProps
                              return (
                                <div className="w-full px-1">
                                  <Input
                                    type="text"
                                    label="紙代"
                                    setFieldValue={setFieldValue}
                                    {...field}
                                  />
                                  {meta.touched && meta.error && meta.error}
                                </div>
                              )
                            }}
                          </Field>

                          <Field name="product.print_price">
                            {(fieldProps: FieldProps) => {
                              const { field, meta } = fieldProps
                              return (
                                <div className="w-full px-1">
                                  <Input
                                    type="text"
                                    label="印刷代"
                                    setFieldValue={setFieldValue}
                                    {...field}
                                  />
                                  {meta.touched && meta.error && meta.error}
                                </div>
                              )
                            }}
                          </Field>

                          <Field name="product.cut_price">
                            {(fieldProps: FieldProps) => {
                              const { field, meta } = fieldProps
                              return (
                                <div className="w-full px-1">
                                  <Input
                                    type="text"
                                    label="カット代"
                                    setFieldValue={setFieldValue}
                                    {...field}
                                  />
                                  {meta.touched && meta.error && meta.error}
                                </div>
                              )
                            }}
                          </Field>

                          <Field name="product.delivery_price">
                            {(fieldProps: FieldProps) => {
                              const { field, meta } = fieldProps
                              return (
                                <div className="w-full px-1">
                                  <Input
                                    type="text"
                                    label="送料"
                                    setFieldValue={setFieldValue}
                                    {...field}
                                  />
                                  {meta.touched && meta.error && meta.error}
                                </div>
                              )
                            }}
                          </Field>

                          <Field name="product.discount_type">
                            {(fieldProps: FieldProps) => {
                              const { field, meta } = fieldProps
                              return (
                                <div className="w-full px-1">
                                  <Input
                                    type="text"
                                    label="割引タイプ"
                                    setFieldValue={setFieldValue}
                                    {...field}
                                  />
                                  {meta.touched && meta.error && meta.error}
                                </div>
                              )
                            }}
                          </Field>

                          <Field name="product.cut_type">
                            {(fieldProps: FieldProps) => {
                              const { field, meta } = fieldProps
                              return (
                                <div className="w-full px-1">
                                  <Input
                                    type="text"
                                    label="カットタイプ"
                                    setFieldValue={setFieldValue}
                                    {...field}
                                  />
                                  {meta.touched && meta.error && meta.error}
                                </div>
                              )
                            }}
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-1/2 p-4">
                    <h2 className="text-3xl font-bold">商品設定</h2>
                    <div>
                      <Field name="product.setting">
                        {(fieldProps: FieldProps) => {
                          const { field, meta } = fieldProps
                          const { value, ...rest } = field
                          let prettifyValue = value
                          try {
                            prettifyValue = JSON.stringify(
                              JSON.parse(value),
                              null,
                              2,
                            )
                          } catch {}

                          return (
                            <div className="w-full px-1">
                              <Input
                                type="textarea"
                                label="商品設定"
                                setFieldValue={setFieldValue}
                                value={prettifyValue}
                                {...rest}
                                rows={50}
                              />
                              {meta.touched && meta.error && meta.error}
                            </div>
                          )
                        }}
                      </Field>
                    </div>
                  </div>
                </div>
                <Controller />
              </Form>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

export default Product
