import * as React from 'react'
import hitkun from 'images/hitkun_0-16.gif'

type Props = {
  text: string
}

const Loading: React.FC<Props> = (props) => {
  return (
    <div className="fixed w-screen h-screen flex justify-center top-0 left-0 z-10">
      <div className="absolute opacity-75 w-full h-full bg-white z-20" />
      <div className="flex flex-col justify-center items-center z-50">
        <img src={hitkun} alt="キョロキョロ..." className="h-48" />
        <div className="text-4xl font-bold p-8">{props.text}</div>
      </div>
    </div>
  )
}

export default Loading
