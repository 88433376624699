import dayjs from 'dayjs'
export const columns = [
  {
    title: 'ID',
    field: 'id',
    hozAlign: 'center',
    width: 130,
  },
  {
    title: '作成日時',
    field: 'created_at',
    hozAlign: 'center',
    width: 150,
    formatter: (cell: any) => {
      const unixtime = cell.getValue() * 1000
      if (unixtime === 0) {
        return '--'
      }
      return dayjs(unixtime).format('YYYY/MM/DD HH:mm:ss')
    },
  },
  {
    title: '発行済',
    field: 'published_at',
    width: 80,
    align: 'center',
    cssClass: 'hover:opacity-50',
    formatter: 'tickCross',
    formatterParams: {
      allowEmpty: false,
      allowTruthy: true,
      tickElement:
        "<span class='text-green-700'><i class='fa fa-check-square'></i></span>",
      crossElement:
        "<span class='text-gray-700'><i class='far fa-square'></i></span>",
    },
  },
  {
    title: 'コード',
    field: 'code',
    hozAlign: 'center',
    width: 100,
  },
  {
    title: '割引額',
    field: 'amount',
    hozAlign: 'center',
    width: 100,
  },
  {
    title: '割引率',
    field: 'rate',
    hozAlign: 'center',
    width: 110,
  },
  {
    title: '期限',
    field: 'expiration',
    hozAlign: 'left',
    width: 150,
    formatter: (cell: any) => {
      const unixtime = cell.getValue() * 1000
      if (unixtime === 0) {
        return '--'
      }
      return dayjs(unixtime).format('YYYY/MM/DD HH:mm:ss')
    },
  },
  {
    title: '使用可能回数',
    field: 'usage_limit',
    hozAlign: 'right',
    width: 100,
  },
  {
    title: '最低料金',
    field: 'minimum_amount',
    hozAlign: 'right',
    width: 100,
  },
  {
    title: '備考',
    field: 'note',
    hozAlign: 'left',
    width: 512,
  },
]
