export const getFormSetting = (
  products: any,
  orderStatuses: any,
  order: any,
) => {
  let fields = [
    { field: 'id', label: 'ID', type: 'number', size: 'w-1/4', disabled: true },
    {
      field: 'order_id',
      label: '注文番号',
      type: 'number',
      size: 'w-3/4',
      disabled: true,
    },
    {
      field: 'status',
      label: 'ステータス',
      type: 'select',
      size: 'w-full',
      options: orderStatuses,
    },
    {
      field: 'created_at',
      label: '作成日時',
      type: 'datetime',
      size: 'w-full',
      disabled: true,
    },
    {
      field: 'updated_at',
      label: '更新日時',
      type: 'datetime',
      size: 'w-full',
      disabled: true,
    },
    {
      field: 'deleted_at',
      label: '削除日時',
      type: 'datetime',
      size: 'w-full',
      disabled: true,
    },
    {
      field: 'purchase_id',
      label: '支払いID',
      type: 'number',
      size: 'w-full',
      disabled: true,
    },
    {
      field: 'product_id',
      label: '材質',
      type: 'select',
      size: 'w-full',
      options: products.material,
    },
    { field: 'shape_id', label: '形状', type: 'select', size: 'w-full' },
    { field: 'name', label: '商品名', type: 'text', size: 'w-full' },
    { field: 'num', label: '枚数', type: 'number', size: 'w-1/2' },
    { field: 'size', label: 'サイズ', type: 'number', size: 'w-1/2' },
    { field: 'size_y', label: 'タテ(mm)', type: 'number', size: 'w-1/2' },
    { field: 'size_x', label: 'ヨコ(mm)', type: 'number', size: 'w-1/2' },
    {
      field: 'lamination',
      label: 'ラミネーション',
      type: 'select',
      size: 'w-full',
      options: products.lamination,
    },
    {
      field: 'adhesive',
      label: '接着糊',
      type: 'select',
      size: 'w-full',
      options: products.adhesive,
    },
    {
      field: 'ondemand_foil',
      label: 'オンデマンド箔加工',
      type: 'select',
      size: 'w-full',
      options: products.ondemandFoil,
    },
    {
      field: 'halfcut',
      label: 'カットラインの本数',
      type: 'select',
      size: 'w-full',
      options: products.halfcut,
    },
    {
      field: 'cutpath',
      label: 'カットパス',
      type: 'select',
      size: 'w-full',
      options: products.cutpath,
    },
    {
      field: 'slit',
      label: '裏スリット',
      type: 'select',
      size: 'w-full',
      options: products.slit,
    },
    {
      field: 'back_side',
      label: '裏面印刷',
      type: 'select',
      size: 'w-full',
      options: products.backSide,
    },
    {
      field: 'delivery_form',
      label: '納品形態',
      type: 'select',
      size: 'w-full',
      options: products.deliveryForm,
    },
    {
      field: 'delivery_date_product',
      label: '商品納期',
      type: 'number',
      size: 'w-1/2',
    },
    {
      field: 'delivery_date_option',
      label: 'オプション納期',
      type: 'number',
      size: 'w-1/2',
    },
    {
      field: 'amount_product',
      label: '商品価格',
      type: 'number',
      size: 'w-1/2',
    },
    {
      field: 'amount_option',
      label: 'オプション価格',
      type: 'number',
      size: 'w-1/2',
    },
    {
      field: 'amount_total',
      label: '計算後価格',
      type: 'number',
      size: 'w-1/2',
    },
    { field: 'point', label: 'ポイント増減', type: 'number', size: 'w-1/2' },
    { field: 'repeat_id', label: 'リピートID', type: 'number', size: 'w-full' },
    {
      field: 'white_ink',
      label: 'ホワイトインク',
      type: 'select',
      size: 'w-1/2',
      options: products.whiteInk,
    },
    {
      field: 'send_copy',
      label: '控え発送の有無',
      type: 'select',
      size: 'w-1/2',
      options: products.sendCopy,
    },
    {
      field: 'shipping_date',
      label: '発送予定日',
      type: 'date',
      size: 'w-full',
    },
    {
      field: 'cutline_service',
      label: 'カットライン作成代行サービス',
      type: 'select',
      size: 'w-1/2',
      options: products.cutlineService,
    },
    {
      field: 'whitedata_service',
      label: 'ホワイトデータ作成代行サービス',
      type: 'select',
      size: 'w-1/2',
      options: products.whitedataService,
    },
    {
      field: 'individual_packaging',
      label: '個包装',
      type: 'select',
      size: 'w-full',
      options: products.individualPackaging,
    },
    {
      field: 'free_format_option_title',
      label: '追加オプション件名',
      type: 'text',
      size: 'w-full',
    },
    {
      field: 'free_format_option_body',
      label: '追加オプション内容',
      type: 'textarea',
      size: 'w-full',
    },
    {
      field: 'free_format_option_amount',
      label: '追加オプション金額',
      type: 'number',
      size: 'w-1/2',
    },
    {
      field: 'free_format_option_repeat',
      label: '追加オプションリピート反映',
      type: 'select',
      size: 'w-1/2',
      options: [
        { name: 'しない', value: false },
        { name: 'する', value: true },
      ],
    },
    {
      field: 'can_example_use',
      label: '事例としての利用可否(0:不可,1:可能)',
      type: 'select',
      size: 'w-1/2',
      options: [
        { name: '不可', value: false },
        { name: '可能', value: true },
      ],
    },
    {
      field: 'delivery_provider',
      label: '配送業者',
      type: 'select',
      size: 'w-1/2',
      options: [
        { name: '未配送', value: '未配送' },
        { name: 'ゆうパック', value: 'ゆうパック' },
        { name: 'ゆうパケット', value: 'ゆうパケット' },
        { name: '西濃運輸', value: '西濃運輸' },
        { name: '福山通運', value: '福山通運' },
        { name: 'ヤマト運輸', value: 'ヤマト運輸' },
        { name: '佐川急便', value: '佐川急便' },
      ],
    },
    {
      field: 'tracking_number',
      label: '伝票番号',
      type: 'text',
      size: 'w-full',
    },
    {
      field: 'shipment_id',
      label: '発送管理ID',
      type: 'text',
      size: 'w-1/2',
      disabled: true,
    },
    {
      field: 'shipment_id_sendcopy',
      label: '発送管理ID(控え発送)',
      type: 'text',
      size: 'w-1/2',
      disabled: true,
    },
    {
      field: 'shipment_memo',
      label: '配送メモ',
      type: 'textarea',
      size: 'w-full',
      height: 'h-64',
    },
  ]
  if (order.product_id === 159) {
    // producId: 159 は 見積り案件
    fields = [
      { field: 'id', label: 'ID', type: 'number', size: 'w-1/4' },
      { field: 'order_id', label: '注文番号', type: 'number', size: 'w-3/4' },
      {
        field: 'status',
        label: 'ステータス',
        type: 'select',
        size: 'w-full',
        options: orderStatuses,
      },
      {
        field: 'created_at',
        label: '作成日時',
        type: 'datetime',
        size: 'w-full',
      },
      {
        field: 'updated_at',
        label: '更新日時',
        type: 'datetime',
        size: 'w-full',
      },
      {
        field: 'deleted_at',
        label: '削除日時',
        type: 'datetime',
        size: 'w-full',
      },
      {
        field: 'purchase_id',
        label: '支払いID',
        type: 'number',
        size: 'w-full',
      },
      { field: 'name', label: '商品名', type: 'text', size: 'w-full' },
      { field: 'point', label: 'ポイント増減', type: 'number', size: 'w-1/3' },
      {
        field: 'shipping_date',
        label: '発送予定日',
        type: 'date',
        size: 'w-full',
      },
      {
        field: 'delivery_provider',
        label: '配送業者',
        type: 'select',
        size: 'w-1/2',
        options: [
          { name: '未配送', value: '未配送' },
          { name: 'ゆうパック', value: 'ゆうパック' },
          { name: 'ゆうパケット', value: 'ゆうパケット' },
          { name: '西濃運輸', value: '西濃運輸' },
          { name: '福山通運', value: '福山通運' },
          { name: 'ヤマト運輸', value: 'ヤマト運輸' },
          { name: '佐川急便', value: '佐川急便' },
        ],
      },
      {
        field: 'tracking_number',
        label: '伝票番号',
        type: 'text',
        size: 'w-1/2',
      },
      {
        field: 'shipment_memo',
        label: '配送メモ',
        type: 'textarea',
        size: 'w-full',
        height: 'h-64',
      },
    ]
  }

  return fields
}
