import * as React from 'react'
import { Link } from 'react-router-dom'
import logo from 'images/logo.svg'

const Logo: React.FC = () => {
  return (
    <Link to="/">
      <img src={logo} alt="ロゴ" className="h-8" />
    </Link>
  )
}

export default Logo
