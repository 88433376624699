import React from 'react'
import dayjs from 'dayjs'

interface Props {
  log: any[]
}

export const SendMailLog = (props: Props) => {
  const { log } = props
  return (
    <div className="my-4">
      <h2 className="py-1">
        <strong className="text-2xl">メール送信履歴</strong>
        (支払情報に紐づくものを表示しています)
      </h2>
      <div className="text-xs">
        ※メールの本文はログに保持していないので、別途メールボックスを探してください。
      </div>
      <table className="text-xs">
        <thead>
          <tr>
            <th className="p-1 border">送信日時</th>
            <th className="p-1 border">支払番号</th>
            <th className="p-1 border">注文番号</th>
            <th className="p-1 border">通知種別</th>
            <th className="p-1 border">送信者ID(下8桁)</th>
            <th className="p-1 border">宛名</th>
            <th className="p-1 border">宛先Email</th>
            <th className="p-1 border">件名</th>
            <th className="p-1 border">添付ファイル(サイズ)</th>
          </tr>
        </thead>
        <tbody>
          {log.map((l) => {
            return (
              <tr key={l.id}>
                <td className="p-1 border">
                  {dayjs(l.created_at * 1000).format('YYYY/MM/DD HH:mm:ss')}
                </td>
                <td className="p-1 border">{l.purchase_id}</td>
                <td className="p-1 border">{l.order_id}</td>
                <td className="p-1 border">{l.mail_type}</td>
                <td className="p-1 border">{l.send_by.slice(-8)}</td>
                <td className="p-1 border">{l.send_to}</td>
                <td className="p-1 border">{l.email}</td>
                <td className="p-1 border">{l.title}</td>
                <td className="p-1 border">{l.attachment}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
