import { productNameById } from './product'
import dayjs from 'dayjs'

export const isMultisticker = (order: any) => {
  // 全抜きかつ、カットラインの本数2本以上の場合はマルチステッカー扱い
  if (order.delivery_form === 128 && order.halfcut >= 32) {
    return true
  }
  return false
}

export const impositionDataString = (order: any, user: any, products: any) => {
  if (!order || !user || !products) {
    return ''
  }
  const isZenFree = [20, 21, 130, 154].includes(order.product_id)
  let data: string[] = []
  data.push(order.order_id)
  data.push(user.organization)
  data.push(user.family_name)
  data.push(user.given_name)
  data.push('様')
  data.push(productNameById(products, 'material', order.product_id))
  data.push(productNameById(products, 'adhesive', order.adhesive))
  data.push(productNameById(products, 'lamination', order.lamination))
  if (isMultisticker(order)) {
    data.push('マルチステッカー')
  } else {
    data.push(
      isZenFree
        ? '全抜き(無料)'
        : productNameById(products, 'deliveryForm', order.delivery_form),
    )
  }
  const slit = productNameById(products, 'slit', order.slit)
  if (slit === 'あり') {
    data.push(`裏スリット`)
  }
  const backSide = productNameById(products, 'backSide', order.back_side)
  if (backSide === 'あり') {
    data.push(`裏面印刷`)
  }
  data.push(`${order.num}枚`)
  data.push('____丁  ____シート')
  if (order.send_copy === 136) {
    data.push('控え発送あり')
  }
  if (
    order.individual_packaging !== 262 &&
    order.individual_packaging !== 263
  ) {
    data.push('個包装')
  }
  data.push(`${dayjs(order.shipping_date * 1000).format('MM/DD')}発送予定`)
  if (order.can_example_use) {
    data.push('紹介OK')
  }
  return data.join(' ')
}

export const optionsText = (order: any, products: any) => {
  let result = ``
  result = `${result}ホワイトインキ:${productNameById(
    products,
    'whiteInk',
    order.white_ink,
  )},`
  result = `${result}ラミネーション:${productNameById(
    products,
    'lamination',
    order.lamination,
  )},`
  result = `${result}接着剤:${productNameById(
    products,
    'adhesive',
    order.adhesive,
  )},`
  result = `${result}カットラインの本数:${productNameById(
    products,
    'halfcut',
    order.halfcut,
  )}\n`
  result = `${result}カットパス:${productNameById(
    products,
    'cutpath',
    order.cutpath,
  )},`
  result = `${result}裏スリット:${productNameById(
    products,
    'slit',
    order.slit,
  )},`
  result = `${result}裏面印刷:${productNameById(
    products,
    'backSide',
    order.back_side,
  )},`
  result = `${result}仕上がり形態:${productNameById(
    products,
    'deliveryForm',
    order.delivery_form,
  )}\n`
  result = `${result}控え送付${productNameById(
    products,
    'sendCopy',
    order.send_copy,
  )},`
  result = `${result}カットライン作成代行S:${productNameById(
    products,
    'cutlineService',
    order.cutline_service,
  )},`
  result = `${result}ホワイトデータ作成代行S:${productNameById(
    products,
    'whitedataService',
    order.whitedata_service,
  )}`
  return result
}
